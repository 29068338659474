import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  standalone: true,
  imports: [
    MatIconModule
  ]
})
export class WelcomeComponent {

  constructor(
    private titleService: Title
  ) {
    this.titleService.setTitle('Accueil');
  }
}
