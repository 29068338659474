// ---- Description ----

import { DescriptionOption } from "../field-type";

export const defaultDescriptionStandardFieldOption: DescriptionOption =
{
    label: 'Standard',
    hasDescriptionTemplate: false
};

export const defaultDescriptionDynamicFieldOption: DescriptionOption =
{
    label: 'Personnalisé',
    hasDescriptionTemplate: true,
    showDescriptionTemplateField: true
};


// ---- Description - Payment ----

export type PaymentDescriptionParameters = {
    withThirdPart: boolean;
}

export const paymentDescriptionThirdPartFieldOption: DescriptionOption =
{
    label: 'Nom du tiers (client / fournisseur)',
    hasDescriptionTemplate: true,
    descriptionTemplateValue: '{{ thirdParty.mainName }}',
};

export function getPaymentDescriptionFieldOptions(
    { withThirdPart }: PaymentDescriptionParameters
): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultDescriptionStandardFieldOption);
    if (withThirdPart) { descriptionOptions.push(paymentDescriptionThirdPartFieldOption); }
    descriptionOptions.push(defaultDescriptionDynamicFieldOption);

    return descriptionOptions;
}

// ---- Description - Sell Invoices ----

export type SellInvoiceDescriptionParameters = {
    withThirdPart: boolean;
}

export const sellInvoiceDescriptionThirdPartFieldOption: DescriptionOption =
{
    label: 'Nom du tiers (client / fournisseur)',
    hasDescriptionTemplate: true,
    descriptionTemplateValue: '{{ buyer.mainName }}',
};

export function getSellInvoiceDescriptionFieldOptions(
    { withThirdPart }: SellInvoiceDescriptionParameters
): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultDescriptionStandardFieldOption);
    if (withThirdPart) { descriptionOptions.push(sellInvoiceDescriptionThirdPartFieldOption); }
    descriptionOptions.push(defaultDescriptionDynamicFieldOption);

    return descriptionOptions;
}

// ---- Description - Buy Invoices ----

export type BuyInvoiceDescriptionParameters = {
    withThirdPart: boolean;
}

export const buyInvoiceDescriptionThirdPartFieldOption: DescriptionOption =
{
    label: 'Nom du tiers (client / fournisseur)',
    hasDescriptionTemplate: true,
    descriptionTemplateValue: '{{ seller.mainName }}',
};

export function getBuyInvoiceDescriptionFieldOptions(
    { withThirdPart }: BuyInvoiceDescriptionParameters
): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultDescriptionStandardFieldOption);
    if (withThirdPart) { descriptionOptions.push(buyInvoiceDescriptionThirdPartFieldOption); }
    descriptionOptions.push(defaultDescriptionDynamicFieldOption);

    return descriptionOptions;
}