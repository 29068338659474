<div class="page-title">
  <h1><mat-icon color="primary">contact_support</mat-icon>&nbsp;Contacter le support</h1>
</div>

<div class="row-page-content">
  <div class="adaptative-half-page-content">
    <div class="section">
      Si vous souhaitez remonter un problème ou avez besoin d’aide avec l’utilisation de la plateforme Klafuti, merci d’envoyer un email à <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>
    </div>
  </div>
</div>