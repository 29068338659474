
<form [formGroup]="formGroup">
    <label for="folderId"><h4><mat-icon>description</mat-icon>&nbsp;Format du fichier FEC</h4></label>
    <mat-form-field class="full-width">
      <mat-label>Format du fichier FEC</mat-label>
      <mat-select formControlName="outputFormat" required>
          <mat-option 
              *ngFor="let format of fecOutputFormatList"
              [value]="format.value">
              {{format.label}}
          </mat-option>
      </mat-select>
  </mat-form-field>
    <label for="generatePartialLettering"><h4><mat-icon>account_tree</mat-icon>&nbsp;Lettrage</h4></label>
    <mat-slide-toggle formControlName="generatePartialLettering">
      <div class="text-and-icon-container">
        <span>Générer les lettrages partiels disponibles</span>
      </div>
    </mat-slide-toggle>
</form>