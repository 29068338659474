export type InvoiceGenerationOptionEnumType = { label: string, value: InvoiceGenerationOptionEnum};

export enum InvoiceGenerationOptionEnum {
    ONE_LINE_PER_PRODUCT_ACCOUNT = 'ONE_LINE_PER_PRODUCT_ACCOUNT',
    ONE_LINE_PER_INVOICE_LINE = 'ONE_LINE_PER_INVOICE_LINE',
    ONE_LINE_PER_VAT_RATE = 'ONE_LINE_PER_VAT_RATE',
    ONE_GLOBAL_LINE = 'ONE_GLOBAL_LINE'
}

export const sellInvoiceGenerationOptions: InvoiceGenerationOptionEnumType[] = [
    {
        label: 'Une ligne d\'écriture par ligne de facture',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE
    },
    {
        label: 'Une ligne d\'écriture par compte de produit',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT
    },
    {
        label: 'Une ligne d\'écriture par taux de TVA',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_VAT_RATE
    },
    {
        label: 'Une ligne d\'écriture globale',
        value: InvoiceGenerationOptionEnum.ONE_GLOBAL_LINE
    }
]

export const buyInvoiceGenerationOptions: InvoiceGenerationOptionEnumType[] = [
    {
        label: 'Une ligne d\'écriture par ligne de facture',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE
    },
    {
        label: 'Une ligne d\'écriture par compte de charge',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT
    },
    {
        label: 'Une ligne d\'écriture par taux de TVA',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_VAT_RATE
    },
    {
        label: 'Une ligne d\'écriture globale',
        value: InvoiceGenerationOptionEnum.ONE_GLOBAL_LINE
    }
]