// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: '0.11.0-SNAPSHOT',
  supportEmail: 'support@dev.klafuti.com',
  registrationUrl: 'https://happy-pebble-0f6074103-dev.westeurope.4.azurestaticapps.net/nous-contacter',
  errorsWarningSettings: {
    enable: true,
    startingStatusCode: 400,
  },
  inqom: {
    url: 'https://auth.inqom.com',
    clientId: 'Inqom',
    scopes: 'openid+profile+email+apidata+apijobs+offline_access'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
