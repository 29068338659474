import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MigrationAccountingFolderService } from './services/migration-accounting-folder.service';
import { MigrationFileUploadService } from './services/migration-file-upload.service';
import { MigrationFiscalYearService } from './services/migration-fiscal-year.service';
import { MigrationAccountingFolderJournalMappingService } from './services/migration-accounting-folder-journal-mapping.service';
import { MigrationProjectService } from './services/migration-project.service';
import { MigrationJournalService } from './services/migration-journal.service';

@NgModule({
  imports: [
    HttpClientModule
  ]
})
export class MigrationModule {
  static forRoot(): ModuleWithProviders<MigrationModule> {
    return {

      ngModule: MigrationModule,
      providers: [
        MigrationAccountingFolderService,
        MigrationFileUploadService,
        MigrationFiscalYearService,
        MigrationAccountingFolderJournalMappingService,
        MigrationProjectService,
        MigrationJournalService
      ],
    };
   }
}
