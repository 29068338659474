import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-sumup-accounting-entries-file-exporter-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './sumup-ae-file-exporter-configuration.component.html',
  styleUrls: ['./sumup-ae-file-exporter-configuration.component.scss']
})
export class SumpUpAccountingEntriesFileExporterConfigurationComponent {
  formGroup: FormGroup;

  constructor(
    readonly fb: FormBuilder

  ) {
    this.formGroup = fb.group({});
  }
}