import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthGuard } from '~modules/auth/helpers/auth.guard';
import { AnonLayoutComponent } from './layouts/anon-layout/anon-layout.component';
import { LoggedLayoutComponent } from './layouts/logged-layout/logged-layout.component';
import { CompanyRightsGuard } from '~modules/company/guards/company-rights.guard';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { SupportComponent } from './pages/support/support.component';

const routes: Routes = [
  {
    path: 'auth',
    loadComponent: () => AnonLayoutComponent,
    loadChildren: () => import('./pages/auth/auth.pages.module').then(m => m.AuthPagesModule),
  }, {
    path: 'app',
    loadComponent: () => LoggedLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'welcome',
        pathMatch: 'full',
        component: WelcomeComponent,
      },{
        path: 'support',
        pathMatch: 'full',
        component: SupportComponent,
      },{
        path: '',
        canActivateChild: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(CompanyRightsGuard).canActivateChild(next, state)],
        children: [
          {
            path: 'tools',
            loadChildren: () => import('./pages/tools/tools.pages.module').then(m => m.ToolsPagesModule),
          }, {
            path: 'company',
            loadChildren: () => import('./pages/company/company.pages.module').then(m => m.CompanyPageModule),
          }, {
            path: 'folders',
            children: [
              {
                path: ':folderId/connectors',
                loadChildren: () => import('./pages/connectors/connectors.page.module').then(m => m.ConnectorsPageModule),
              },
              {
                path: '',
                loadChildren: () => import('./pages/accounting-folders/accounting-folders.page.module').then(m => m.AccountingFoldersPageModule),
              }
            ],
            
          }, {
            path: 'migration',
            loadChildren: () => import('./pages/migration/migration.pages.module').then(m => m.MigrationPagesModule),
          }, {
            path: 'third-parts',
            loadChildren: () => import('./pages/thirdparts/third-parts.pages.module').then(m => m.ThirdPartsPagesModule),
          }, {
            path: '',
            pathMatch: 'prefix',
            redirectTo: '/app/welcome',
          }
        ]
      }
    ]
  }, {
    path: '',
    pathMatch: 'prefix',
    redirectTo: '/app/welcome',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
