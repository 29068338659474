import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AnalyticAccountRulesSectionFieldsConfiguration, defaultPourcentagePatternValue, pourcentageMaxValue } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { environment } from 'src/environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnalyticRuleTypeEnum, analyticRuleTypes } from '~modules/connectors/constants/analytic-rule-type.option';
import { AnalyticAccountRuleProcessorConfiguration } from '../../entities/analytic-account-rule-processor-configuration.entity';

type AnalyticAccountConfigurationControls = FormGroup<{
  id: FormControl<number | null>;
  type: FormControl<AnalyticRuleTypeEnum | null>;
  account: FormControl<string | null>;
  axis: FormControl<string | null>;
  tag: FormControl<string | null>;
  value: FormControl<number | null>;
}>;

@Component({
  selector: 'app-analytic-account-rule-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ],
  templateUrl: './analytic-account-rule-configuration.component.html',
  styleUrls: ['./analytic-account-rule-configuration.component.scss']
})
export class AnalyticAccountRuleConfigurationComponent {

  analyticRuleTypes = analyticRuleTypes;

  formGroup!: FormGroup;
  formArray!: FormArray<AnalyticAccountConfigurationControls>;

  fieldsConfig: AnalyticAccountRulesSectionFieldsConfiguration | undefined;

  @Input()
  toolLabel!: string | undefined;

  @Input()
  addLabel!: string | undefined;

  get supportEmail() {
    return environment.supportEmail;
  }

  @Input()
  set accountRules(value: AnalyticAccountRuleProcessorConfiguration[] | undefined) {
    if (value) {
      if (this.formArray.controls.length !== 0) {
        this.formArray.clear()
      }
      for(const accountRuleConfiguration of value) {
        this.formArray.push(
          this.createAccountRuleConfigurationGroup(
            accountRuleConfiguration.id,
            accountRuleConfiguration.type,
            accountRuleConfiguration.account,
            accountRuleConfiguration.axis,
            accountRuleConfiguration.tag,
            accountRuleConfiguration.value
          )
        );
      }
    }
  }

  @Input()
  set fieldsConfiguration (value: AnalyticAccountRulesSectionFieldsConfiguration | undefined) {
    if (value != undefined) {
      this.fieldsConfig = value;

      if (this.formArray.controls.length === 0) {
        for(const accountRuleConfiguration of value.accounts || []) {
          this.formArray.push(
            this.createAccountRuleConfigurationGroup(
              null,
              accountRuleConfiguration.type,
              accountRuleConfiguration.account,
              accountRuleConfiguration.axis,
              accountRuleConfiguration.tag,
              accountRuleConfiguration.value
            )
          );
        }
      } else {
        this.formArray.controls.forEach((accountRuleConfiguration) => {
          if (value.columns?.type?.canEdit != null && !value.columns?.type?.canEdit) {
            accountRuleConfiguration.get('type')?.disable();
          } else {
            accountRuleConfiguration.get('type')?.enable();
          }
          if (value.columns?.account?.canEdit != null && !value.columns?.account?.canEdit) {
            accountRuleConfiguration.get('account')?.disable();
          } else {
            accountRuleConfiguration.get('account')?.enable();
          }
          if (value.columns?.axis?.canEdit != null && !value.columns?.axis?.canEdit) {
            accountRuleConfiguration.get('axis')?.disable();
          } else {
            accountRuleConfiguration.get('axis')?.enable();
          }
          if (value.columns?.tag?.canEdit != null && !value.columns?.tag?.canEdit) {
            accountRuleConfiguration.get('tag')?.disable();
          } else {
            accountRuleConfiguration.get('tag')?.enable();
          }
          if (value.columns?.value?.canEdit != null && !value.columns?.value?.canEdit) {
            accountRuleConfiguration.get('value')?.disable();
          } else {
            accountRuleConfiguration.get('value')?.enable();
          }
        })
      }

      this.formGroup.markAllAsTouched();
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formArray = new FormArray<AnalyticAccountConfigurationControls>([]);
    this.formGroup = new FormGroup({
      formArray: this.formArray
    });
  }

  addAccountRule() {
    const paymentMethodConfigurationGroup: AnalyticAccountConfigurationControls = this.createAccountRuleConfigurationGroup(
      null,
      this.fieldsConfig?.columns?.type?.defaultValue || null,
      this.fieldsConfig?.columns?.account?.defaultValue || null,
      this.fieldsConfig?.columns?.axis?.defaultValue || null,
      this.fieldsConfig?.columns?.tag?.defaultValue || null,
      this.fieldsConfig?.columns?.value?.defaultValue || null
    );
    this.formArray.push(paymentMethodConfigurationGroup);
  }

  deleteAccountRule(index: number) {
    this.formArray.removeAt(index);
  }

  createAccountRuleConfigurationGroup(
    id: number | null = null,
    type: AnalyticRuleTypeEnum | null = null,
    account: string | null = null,
    axis: string | null = null,
    tag: string | null = null,
    value: number | null = null
  ): AnalyticAccountConfigurationControls {
    const result: AnalyticAccountConfigurationControls = new FormGroup({
      id: new FormControl<number | null>(id),
      type: new FormControl<AnalyticRuleTypeEnum | null>(type, [Validators.required]),
      account: new FormControl<string | null>(account, Validators.maxLength(12)),
      axis: new FormControl<string | null>(axis, [Validators.required, Validators.maxLength(70)]),
      tag: new FormControl<string | null>(tag, [Validators.required, Validators.maxLength(70)]),
      value: new FormControl<number | null>(value, [Validators.required, Validators.min(0), Validators.max(pourcentageMaxValue), Validators.pattern(defaultPourcentagePatternValue)])
    });
    if (this.fieldsConfig?.columns?.type?.canEdit != null && !this.fieldsConfig?.columns?.type?.canEdit) {
      result.get('type')?.disable();
    }
    if (this.fieldsConfig?.columns?.account?.canEdit != null && !this.fieldsConfig?.columns?.account?.canEdit) {
      result.get('account')?.disable();
    }
    if (this.fieldsConfig?.columns?.axis?.canEdit != null && !this.fieldsConfig?.columns?.axis?.canEdit) {
      result.get('axis')?.disable();
    }
    if (this.fieldsConfig?.columns?.tag?.canEdit != null && !this.fieldsConfig?.columns?.tag?.canEdit) {
      result.get('tag')?.disable();
    }
    if (this.fieldsConfig?.columns?.value?.canEdit != null && !this.fieldsConfig?.columns?.value?.canEdit) {
      result.get('value')?.disable();
    }
    return result;
  }

  onTypeChange(event: string, accountRule: AnalyticAccountConfigurationControls): void {
    if (event === 'ALL') {
      accountRule.controls.account.patchValue(null);
      accountRule.controls.account.disable();
    } else {
      accountRule.controls.account.enable();
    }
  }

  compareFunction(o1: AnalyticRuleTypeEnum, o2: AnalyticRuleTypeEnum) {
    return (o1 == null && o2 == null) || (o1 != null && o2 != null && o1 === o2);
  }
}