import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { FecOutputFormatType, FecOutputFormatsService } from '../../services/fec-output-formats.service';

@Component({
  selector: 'app-load-fec-download-configuration',
  templateUrl: './load-fec-download-configuration.component.html',
  standalone: true,
  providers: [
    FecOutputFormatsService
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatSlideToggleModule
  ]
})
export class LoadFecDownloadConfigurationComponent {
  formGroup!: FormGroup;
  fecOutputFormatList!: FecOutputFormatType[];

  constructor(
    readonly fb: FormBuilder,
    readonly fecOutputFormatsService: FecOutputFormatsService
  ) {
    this.fecOutputFormatList = fecOutputFormatsService.getAll();

    this.formGroup = fb.group({
      outputFormat: ['STANDARD', [Validators.required]],
      generatePartialLettering: [false, Validators.required]
    });
  }
}
