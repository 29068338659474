import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-ubereats-accounting-entries-file-exporter-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './ubereats-ae-file-exporter-configuration.component.html',
  styleUrls: ['./ubereats-ae-file-exporter-configuration.component.scss']
})
export class UberEatsAccountingEntriesFileExporterConfigurationComponent {
  formGroup: FormGroup;

  constructor(
    readonly fb: FormBuilder

  ) {
    this.formGroup = fb.group({});
  }
}