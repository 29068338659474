import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';
import { defaultAccountLabelMaxLength, defaultMainAccountMaxLength, defaultAuxiliaryAccountMaxLength, defaultDynamicConfigMaxLength, ThirdPartyAccountSectionFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { VatAccountConfigurationComponent } from '../vat-account-configuration/vat-account-configuration.component';
import { InvoiceThirdPartyProcessorConfiguration } from '../../entities/invoice-third-party-processor-configuration.entity';

@Component({
  selector: 'app-invoice-third-party-processor-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    VatAccountConfigurationComponent,

    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
  ],
  templateUrl: './invoice-third-party-processor-configuration.component.html',
  styleUrls: ['./invoice-third-party-processor-configuration.component.scss']
})
export class InvoiceThirdPartyProcessorConfigurationComponent implements OnDestroy {

  formGroup!: FormGroup;

  @Input()
  toolLabel!: string | undefined;
  thirdPartyTypeLabel = 'client';

  hasDynamicSubscription?: Subscription;
  fieldsConfig: ThirdPartyAccountSectionFieldsConfiguration | undefined;

  @Input()
  set thirdPartyType(value: 'CLIENT'|'SUPPLIER') {
    if (value === 'CLIENT') {
      this.thirdPartyTypeLabel = 'client';
    } else if (value === 'SUPPLIER') {
      this.thirdPartyTypeLabel = 'fournisseur';
    }
  }

  @Input()
  set thirdPartyAccount(value: InvoiceThirdPartyProcessorConfiguration | undefined) {
    if (value) {
      this.formGroup.patchValue(value);
    }
  }

  @Input()
  set fieldsConfiguration (value: ThirdPartyAccountSectionFieldsConfiguration | undefined) {
    if (value != undefined) {
      this.fieldsConfig = value;

      const accountMaxLength = value.account.maxLength != null ? value.account.maxLength : defaultMainAccountMaxLength;
      const accountLabelMaxLength = value.label.maxLength != null ? value.label.maxLength : defaultAccountLabelMaxLength;
      const auxiliaryAccountStaticMaxLength = value.auxiliaryAccountStatic.maxLength != null ? value.auxiliaryAccountStatic.maxLength : defaultAuxiliaryAccountMaxLength;
      const auxiliaryAccountLabelStaticMaxLength = value.auxiliaryLabelStatic.maxLength != null ? value.auxiliaryLabelStatic.maxLength : defaultAccountLabelMaxLength;
      const auxiliaryAccountDynamicMaxLength = value.auxiliaryAccountDynamic.maxLength != null ? value.auxiliaryAccountDynamic.maxLength : defaultDynamicConfigMaxLength;
      const auxiliaryAccountLabelDynamicMaxLength = value.auxiliaryLabelDynamic.maxLength != null ? value.auxiliaryLabelDynamic.maxLength : defaultDynamicConfigMaxLength;

      this.formGroup.get('useSuggestedAccounts')?.setValue(value.useSuggestedAccounts.defaultValue);
      this.formGroup.get('account')?.setValidators([Validators.required, Validators.maxLength(accountMaxLength)]);
      this.formGroup.get('account')?.setValue(value.account.defaultValue);
      this.formGroup.get('accountLabel')?.setValidators([Validators.maxLength(accountLabelMaxLength)]);
      this.formGroup.get('accountLabel')?.setValue(value.label.defaultValue);
      this.formGroup.get('hasDynamic')?.setValue(value.isDynamic.defaultValue);
      this.formGroup.get('auxiliaryAccountStatic')?.setValidators([Validators.maxLength(auxiliaryAccountStaticMaxLength)]);
      this.formGroup.get('auxiliaryAccountLabelStatic')?.setValidators([Validators.maxLength(auxiliaryAccountLabelStaticMaxLength)]);
      this.formGroup.get('auxiliaryAccountDynamic')?.setValidators([Validators.maxLength(auxiliaryAccountDynamicMaxLength)]);
      this.formGroup.get('auxiliaryAccountLabelDynamic')?.setValidators([Validators.maxLength(auxiliaryAccountLabelDynamicMaxLength)]);
      if (value.isDynamic.defaultValue === true) {
        this.formGroup.get('auxiliaryAccountStatic')?.setValue(null);
        this.formGroup.get('auxiliaryAccountLabelStatic')?.setValue(null);
        this.formGroup.get('auxiliaryAccountDynamic')?.setValue(value.auxiliaryAccountDynamic.defaultValue);
        this.formGroup.get('auxiliaryAccountLabelDynamic')?.setValue(value.auxiliaryLabelDynamic.defaultValue);
      } else {
        this.formGroup.get('auxiliaryAccountStatic')?.setValue(value.auxiliaryAccountStatic.defaultValue);
        this.formGroup.get('auxiliaryAccountLabelStatic')?.setValue(value.auxiliaryLabelStatic.defaultValue);
        this.formGroup.get('auxiliaryAccountDynamic')?.setValue(null);
        this.formGroup.get('auxiliaryAccountLabelDynamic')?.setValue(null);
      }

      this.formGroup.markAllAsTouched();
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formGroup = new FormGroup({
      useSuggestedAccounts: new FormControl(null, [Validators.required]),
      account: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      accountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      hasDynamic: new FormControl(false, [Validators.required]),
      auxiliaryAccountStatic: new FormControl('', [Validators.maxLength(defaultAuxiliaryAccountMaxLength)]),
      auxiliaryAccountLabelStatic: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      auxiliaryAccountDynamic: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)]),
      auxiliaryAccountLabelDynamic: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)])
    });

    this.hasDynamicSubscription = this.formGroup.get('hasDynamic')?.valueChanges.subscribe({
      next: (value) => {
        if (value === true) {
          // put to null static fields
          this.formGroup.get('auxiliaryAccountStatic')?.setValue(null);
          this.formGroup.get('auxiliaryAccountLabelStatic')?.setValue(null);

          // update auxiliary account default value
          if (this.formGroup.get('auxiliaryAccountDynamic')?.getRawValue() === null) {
            const defaultAuxiliaryAccountDynamic = this.fieldsConfig?.auxiliaryAccountDynamic?.defaultValue || `{{ thirdParty.mainName | normalize | upper | left(8) }}`;
            this.formGroup.get('auxiliaryAccountDynamic')?.setValue(defaultAuxiliaryAccountDynamic);
          }

          // update label default value
          if (this.formGroup.get('auxiliaryAccountLabelDynamic')?.getRawValue() === null) {
            const defaultAccountLabelDynamic = this.fieldsConfig?.auxiliaryLabelDynamic?.defaultValue || `{{ thirdParty.mainName }}`;
            this.formGroup.get('auxiliaryAccountLabelDynamic')?.setValue(defaultAccountLabelDynamic);
          }
        } else {
          // put to null dynamic fields
          this.formGroup.get('auxiliaryAccountDynamic')?.setValue(null);
          this.formGroup.get('auxiliaryAccountLabelDynamic')?.setValue(null);
          
          // update auxiliary account default value
          if (this.formGroup.get('auxiliaryAccountStatic')?.getRawValue() === null) {
            const defaultAuxiliaryAccountStatic = this.fieldsConfig?.auxiliaryAccountStatic?.defaultValue || this.toolLabel;
            this.formGroup.get('auxiliaryAccountStatic')?.setValue(defaultAuxiliaryAccountStatic);
          }

          // update label default value
          if (this.formGroup.get('auxiliaryAccountLabelStatic')?.getRawValue() === null) {
            const defaultAccountLabelStatic = this.fieldsConfig?.auxiliaryLabelStatic?.defaultValue || this.toolLabel;
            this.formGroup.get('auxiliaryAccountLabelStatic')?.setValue(defaultAccountLabelStatic);
          }
        }

        // validate form
        this.formGroup.get('auxiliaryAccountStatic')?.updateValueAndValidity();
        this.formGroup.get('auxiliaryAccountLabelStatic')?.updateValueAndValidity();
        this.formGroup.get('auxiliaryAccountDynamic')?.updateValueAndValidity();
        this.formGroup.get('auxiliaryAccountLabelDynamic')?.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(): void {
    this.hasDynamicSubscription?.unsubscribe();
  }

  avoidLineBreak(event: KeyboardEvent) {
    if(event.key == 'Enter' || (event.shiftKey && event.key == 'Enter')) {
      event.preventDefault();
    }
  }
}