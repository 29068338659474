import { RunFieldsConfiguration } from "../../field-type";


export const shopifyFileRunFieldsConfiguration: RunFieldsConfiguration = {

    runCursor: {
        visible: false
    },
    file: {
        visible: true,
        mimeType: "text/csv"
    }

}