import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GlobalConnectorsService } from "./global-connectors.service";
import { RunIterationConnectorListItemResponse } from "../dtos/run-iteration-connector-list-item.response";
import { Observable } from "rxjs";

@Injectable()
export class ConnectorRunsService {

    constructor(
        private readonly http: HttpClient,
    ) {}

    
    public static getPath(
        folderId: string,
        connectorId: string,
    ) {
        return `${GlobalConnectorsService.getPath(folderId)}/${connectorId}/${ConnectorRunsService.getFragmentPath()}`;
    }

    static getFragmentPath(): string {
        return `runs`;
    }

    getAllByFolderIdAndConnectorId(
        folderId: string,
        connectorId: string
    ): Observable<RunIterationConnectorListItemResponse[]> {
        const url = `${ConnectorRunsService.getPath(folderId, connectorId)}`;
        return this.http.get<RunIterationConnectorListItemResponse[]>(url);
    }
}