export type AxonautFilterDateOptionEnumType = { label: string, value: AxonautFilterDateOptionEnum};

export enum AxonautFilterDateOptionEnum {
    INVOICE = 'INVOICE',
    CREATION = 'CREATION'
}

export const axonautFilterDateOptions: AxonautFilterDateOptionEnumType[] = [
    {
        label: 'Extraction basée sur la date du document (facture, reçu…)',
        value: AxonautFilterDateOptionEnum.INVOICE
    },
    {
        label: 'Extraction basée sur la date de création du document (facture, reçu…)',
        value: AxonautFilterDateOptionEnum.CREATION
    }
]