import { Injectable } from "@angular/core";
import { TransformConnectorType } from "../models/transform-connector.type";
import { TransformFormatType } from "../constants/transform-format.type";
import { AccountingEntriesProcessorConfigurationComponent } from "~modules/tools/extract-accounting-entries/components/accounting-entries-processor-configuration/accounting-entries-processor-configuration.component";


const transformConnectorsNodeList: TransformConnectorType[] = [{
    configurationComponent: AccountingEntriesProcessorConfigurationComponent,
    type: 'ACCOUNTING_ENTRIES_PROCESSOR',
    iconUrl: '/assets/img/connectors/to-accouting_300x300.png',
    label: 'Écriture Comptable',
    outputType: 'ACCOUNTING_ENTRIES',
    inputType: 'ACCOUNTING_ENTRIES',
}];

@Injectable()
export class TransformConnectorsService {

    getList(): TransformConnectorType[] {
        return [...transformConnectorsNodeList];
    }

    getAllByInputType(
        inputType: TransformFormatType
    ): TransformConnectorType[] {
        return [...transformConnectorsNodeList.filter(item => item.inputType === inputType)];
    }
}
