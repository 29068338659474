import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CompanyService } from './services/company.service';
import { CurrentCompanyService } from './services/current-company.service';
import { CompanyRightsGuard } from './guards/company-rights.guard';
import { CurrentCompanyRightDirective } from './directives/curent-company-right.directive';
import { CurrentCompanyRightsDirective } from './directives/curent-company-rights.directive';
import { CurrentCompanyOfferConnectorsThirdPartDirective } from './directives/curent-company-offer-connectors-third-part.directive';
import { CompanyOfferConnectorsThirdPartsGuard } from './guards/company-offer-connectors-third-parts.guard';

@NgModule({
  imports: [
    HttpClientModule
  ],
  declarations: [
    CurrentCompanyRightDirective,
    CurrentCompanyRightsDirective,
    CurrentCompanyOfferConnectorsThirdPartDirective
  ],
  exports: [
    CurrentCompanyRightDirective,
    CurrentCompanyRightsDirective,
    CurrentCompanyOfferConnectorsThirdPartDirective
  ]
})
export class CompanyModule {
  static forRoot(): ModuleWithProviders<CompanyModule> {
    return {
      ngModule: CompanyModule,
      providers: [
        CompanyService,
        CurrentCompanyService,
        CompanyRightsGuard,
        CompanyOfferConnectorsThirdPartsGuard
      ],
    };
   }
}
