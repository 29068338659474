import { Injectable } from "@angular/core";


export type FecOutputFormatType = {
    label: string;
    value: 'STANDARD' | 'CSV';
};

@Injectable()
export class FecOutputFormatsService {
    
    private readonly outputFormatList: FecOutputFormatType[] = [
        {
            label: "FEC standard (TXT, séparation par tabulations)",
            value: "STANDARD"
        },
        {
            label: "FEC CSV (CSV, séparation par points-virgules)",
            value: "CSV"
        }
    ];

    public getAll(): FecOutputFormatType[] {
        return [...this.outputFormatList]; // to avoid side effects
    }
}