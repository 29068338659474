<div class="page-title">
  <h1><mat-icon color="primary">home</mat-icon>&nbsp;Accueil</h1>
</div>

<div class="row-page-content">
  <div class="adaptative-half-page-content">
    <div class="section">
      Bonjour et bienvenue sur KLAFUTI.<br/>
      <br/>
      Vous pouvez accéder à nos différents outils via le menu latéral.
    </div>
  </div>
</div>