import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MigrationAccountingFolderList } from '../entities/migration-accounting-folder-list.entity';
import { MigrationAccountingFolderDetails } from '../entities/details/migration-accounting-folder-details.entity';
import { MigrationAccountingFolderDetailsFileList } from '../entities/details/migration-accounting-folder-details-file-list.entity';
import { MigrationAccountingFolderDetailsFiscalYearList } from '../entities/details/migration-accounting-folder-details-fiscal-year-list.entity';
import { MigrationAccountingFolderJournalMappingUpdateList } from '../entities/mapping/folder/migration-accounting-folder-journal-mapping-update-list.entity';
import { MigrationAccountingFolderUpdateMigrationProjectRequest } from '../entities/details/migration-accounting-folder-update-migration-project-request.entity';
import { MigrationAccountingFolderJournalUpdateList } from '../entities/journal/migration-accounting-folder-journal-update-list.entity';

@Injectable()
export class MigrationAccountingFolderService {

  constructor(
      private http: HttpClient,
  ) { }

  listAllFolders$(): Observable<MigrationAccountingFolderList[]> {
    const url = `/api/migration/folder/list`;
    return this.http.get<MigrationAccountingFolderList[]>(url);
  }

  getDetails$(id: number): Observable<MigrationAccountingFolderDetails> {
    const url = `/api/migration/folder/${id}`;
    return this.http.get<MigrationAccountingFolderDetails>(url);
  }

  updateMigrationProject$(id: number, migrationProjectId: number): Observable<void> {
    const url = `/api/migration/folder/${id}/project`;
    const body: MigrationAccountingFolderUpdateMigrationProjectRequest = {
      migrationProjectId
    }
    return this.http.post<void>(url, body);
  }

  getDetailsFileById$(id: number): Observable<MigrationAccountingFolderDetailsFileList[]> {
    const url = `/api/migration/folder/${id}/files`;
    return this.http.get<MigrationAccountingFolderDetailsFileList[]>(url);
  }

  getDetailsFiscalYearById$(id: number): Observable<MigrationAccountingFolderDetailsFiscalYearList[]> {
    const url = `/api/migration/folder/${id}/fiscalYears`;
    return this.http.get<MigrationAccountingFolderDetailsFiscalYearList[]>(url);
  }

  downloadAccountingAssetsById$(id: number): Observable<Blob> {
    const url = `/api/migration/folder/${id}/accountingAssets`;

    return this.http.get(url, {responseType: 'blob'})
  }

  deleteOne$(id: number): Observable<void> {
    const path = `api/migration/folder/${id}`;
    return this.http.delete<void>(path);
  }

  createOrUpdateJournalMapping$(id: number): Observable<void> {
    const url = `/api/migration/folder/${id}/journalMappings`;
    return this.http.post<void>(url, null)
  }

  getJournalMappingById$(id: number): Observable<MigrationAccountingFolderJournalMappingUpdateList[]> {
    const url = `/api/migration/folder/${id}/journalMappings`;
    return this.http.get<MigrationAccountingFolderJournalMappingUpdateList[]>(url)
  }

  getJournalsById$(id: number): Observable<MigrationAccountingFolderJournalUpdateList[]> {
    const url = `/api/migration/folder/${id}/journals`;
    return this.http.get<MigrationAccountingFolderJournalUpdateList[]>(url)
  }

  deleteAllAccountingAssets$(id: number): Observable<void> {
    const url = `/api/migration/folder/${id}/injector/accountingAssets`;
    return this.http.delete<void>(url)
  }

  createAllAccountingAssets$(id: number): Observable<void> {
    const url = `/api/migration/folder/${id}/injector/accountingAssets`;
    return this.http.post<void>(url, null)
  }
}
