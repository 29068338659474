import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MigrationAccountingFileUploadParam } from '../entities/migration-accounting-file-upload-param.entity';
import { MigrationAccountingFile } from '../entities/migration-accounting-file.entity';

@Injectable()
export class MigrationFileUploadService {

  constructor(
      private http: HttpClient,
  ) { }

  uploadFile$(siren: string, fileformat: string, file: File): Observable<MigrationAccountingFile> {
    const url = `/api/migration/upload`;

    const param: MigrationAccountingFileUploadParam = {
      format: fileformat,
      siren: siren
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("param", JSON.stringify(param));

    return this.http.post<MigrationAccountingFile>(url, formData);
  }

  downloadFile$(id: number): Observable<Blob> {
    const url = `/api/migration/upload/${id}`;

    return this.http.get(url, {responseType: 'blob'});
  }
}
