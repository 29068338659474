import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  standalone: true,
  imports: [
    MatIconModule
  ]
})
export class SupportComponent {

  constructor(
    private titleService: Title
  ) {
    this.titleService.setTitle('Contacter le support');
  }
  
  get supportEmail() {
    return environment.supportEmail;
  }
}
