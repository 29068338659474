import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { CurrentCompanyService } from '../services/current-company.service';
import { CompanyDomain } from '../entities/company-domain.entity';
import { CompanyRight } from '../entities/company-right.entity';

@Directive({
    selector: '[appCurrentCompanyRights]'
})
export class CurrentCompanyRightsDirective {

    constructor(
        private readonly templateRef: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
        private readonly currentCompanyService: CurrentCompanyService,
    ) {}

    @Input()
    set appCurrentCompanyRights(domainRights: {domain: CompanyDomain, right: CompanyRight}[]) {
        this.currentCompanyService
            .currentUserCanAccessOneOf$(domainRights)
            .pipe(
                first()
            ).subscribe(res => {
                if (res === true) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }
}
