import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ThirdPartsAuthenticationList } from '~modules/thirdparts/common/entities/third-parts-authentication-list.entity';
import { ThirdPartsAxonautAuthenticationManualCreateUpdateRequest } from '../entities/third-parts-axonaut-authentication-manual-create-update-request.entity';

@Injectable()
export class ThirdPartsAxonautAuthenticationService {

  constructor(private readonly http: HttpClient) { }

  public list$(): Observable<ThirdPartsAuthenticationList[]> {
    const path = `/api/third-parts/axonaut/authentication/list`;
    return this.http.get<ThirdPartsAuthenticationList[]>(path);
  }

  public createOrUpdate$(request: ThirdPartsAxonautAuthenticationManualCreateUpdateRequest) {
    const url = `/api/third-parts/axonaut/authentication`;

    return this.http.post<void>(url, request);
  }
}
