<ng-container [formGroup]="formGroup">
  <ng-container formArrayName="formArray">
    <ng-container *ngIf="fieldsConfig?.unique === true" formGroupName="0">
      <div class="row">
        <mat-form-field class="third-width">
          <mat-label>Compte d'instrument financier</mat-label>
          <input matInput formControlName="providerAccount" [placeholder]="fieldsConfig?.columns?.provider?.defaultValue?.account || ''">
        </mat-form-field>
        <mat-form-field class="two-thirds-width">
          <mat-label>Libellé du compte d'instrument financier</mat-label>
          <input matInput formControlName="providerAccountLabel" [placeholder]="fieldsConfig?.columns?.provider?.defaultValue?.label || ''">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="third-width">
          <mat-label>Compte de transfert interne</mat-label>
          <input matInput formControlName="internalTransferAccount" [placeholder]="fieldsConfig?.columns?.internalTransfer?.defaultValue?.account || ''">
        </mat-form-field>
        <mat-form-field class="two-thirds-width">
          <mat-label>Libellé de compte de transfert interne</mat-label>
          <input matInput formControlName="internalTransferAccountLabel" [placeholder]="fieldsConfig?.columns?.internalTransfer?.defaultValue?.label || ''">
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="fieldsConfig?.unique === false">
      <div class="header-row">
        <div class="header name">Nom</div>
        <div class="header export">Export</div>
        <div class="header journal-code" *ngIf="isJournalVisible === true">Journal</div>
        <div class="header" [ngClass]="{'account': isJournalVisible !== false, 'account-without-journal-code': isJournalVisible === false}">Comptes</div>
      </div>
      <ng-container *ngFor="let account of formArray.controls; index as i" [formGroupName]="i">
        <div class="row flex-center">
          <ng-container *ngIf="i === formArray.controls.length - 1">
            <div class="name default"
              matTooltipPosition="above"
              matTooltip="La méthode suivante sera utilisée par défaut si aucun autre méthode ne correspond."
            >Défaut :</div>
          </ng-container>
          <ng-container *ngIf="i !== formArray.controls.length - 1">
            <mat-form-field class="name">
              <input matInput
                      (keydown.enter)="$event.preventDefault()"
                      formControlName="name"
                      [matAutocomplete]="auto"
                      >
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">{{option}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>
          <mat-slide-toggle formControlName="export" class="export"></mat-slide-toggle>
          <mat-form-field
            *ngIf="isJournalVisible === true"
            class="journal-code"
          >
            <input matInput
                    (keydown.enter)="$event.preventDefault()"
                    formControlName="journalCode"
                    autocomplete="off">
          </mat-form-field>
          <div class="column" [ngClass]="{'account': isJournalVisible !== false, 'account-without-journal-code': isJournalVisible === false}">
            <mat-form-field class="provider">
              <mat-label>Compte d'instrument financier</mat-label>
              <input matInput
                      (keydown.enter)="$event.preventDefault()"
                      formControlName="providerAccount"
                      autocomplete="off">
            </mat-form-field>
            <mat-form-field class="internal-transfer" *ngIf="this.fieldsConfig?.internalTransferVisibleFor?.includes(account.get('name')?.value ?? '')">
              <mat-label>Compte de transfert interne</mat-label>
              <input matInput
                      (keydown.enter)="$event.preventDefault()"
                      formControlName="internalTransferAccount"
                      autocomplete="off">
            </mat-form-field>
          </div>
          <button mat-button (click)="deletePaymentMethod(i)" [disabled]="fieldsConfig?.canDelete === false || i === formArray.controls.length - 1">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </ng-container>
      <button mat-stroked-button (click)="addPaymentMethod()" [disabled]="fieldsConfig?.canAdd === false"><mat-icon>add</mat-icon>&nbsp;{{ addLabel || 'Ajouter une méthode de paiement' }}</button>
    </ng-container>
  </ng-container>
</ng-container>