import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { SessionErrorsManager } from '../services/session-errors-manager.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
    constructor(
        private readonly sessionErrorsManager: SessionErrorsManager,
        private readonly router: Router
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next
            .handle(request)
            .pipe(
                tap ({
                    error: async (error) => {
                      console.error(error);
                        if (error instanceof HttpErrorResponse && error.status >= 400) {
                            let errorMessage = "";
                            if (error.status === 504) {
                              errorMessage = error.error;
                            } else {
                                if (error.error != null) {
                                  // read json
                                  if(error.error instanceof Blob) {
                                    const errorBlob: Blob = error.error as Blob;
                                    if(errorBlob.type == "application/json") {
                                      const errorObject = JSON.parse(await errorBlob.text());
                                      if(errorObject.message != null) {
                                        errorMessage = errorObject.message;
                                      }
                                    } else {
                                      console.error(`Error with blob of type ${errorBlob.type} is not managed`);
                                    }
                                  } else if (error.error.message != null && Array.isArray(error.error.message)) {
                                    errorMessage = error.error.message.join(',');
                                  } else  {
                                      errorMessage = error.error.message;
                                  }
                                } else if (error.message != null) {
                                  errorMessage = error.message;
                                }
                            }
                            const settings = environment.errorsWarningSettings;
                            if (
                              settings.enable === true
                              && error.status >= settings.startingStatusCode
                            ) {
                              this.sessionErrorsManager.add({
                                msg: 'Une erreur est survenue, merci de contacter votre administrateur.',
                                additionalInformations: {
                                    status: `${error.status} - ${error.statusText}`,
                                    url: `${request.method} - ${request.urlWithParams}`,
                                    date: new Date().toISOString(),
                                    errorMessage,
                                    route: this.router.url
                                }
                            });
                            }
                        }
                    }
                })
            );
    }
}
