import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { InqomState } from '../entities/third-parts-authentication-inqom-state.entity';
import { ThirdPartsAuthenticationInqomManualCreateUpdateRequest } from '../entities/third-parts-authentication-inqom-manual-create-update-request.entity';
import { ThirdPartsAuthenticationList } from '~modules/thirdparts/common/entities/third-parts-authentication-list.entity';
import { AuthenticationCallbackState } from '~modules/thirdparts/common/entities/authentication-callback-state.constant';

@Injectable()
export class ThirdPartsAuthenticationInqomService {

  constructor(private readonly http: HttpClient) { }

  /**
   * Generate a link to the authenticate page of home.inqom.com and then do a redirection to this link
   * @param state the state to restore the application
   * @param codeChallenge a code that must be use after authentication to get the password
   */
  public authorizeInqomApplication(state: string, codeChallenge: string): void {
    // TODO NOT WORKING BECAUSE URI IS NOT AUTHORIZED FOR INQOM CLIENT, NEED TO ASK A CLIENT
    const url =
      `${ environment.inqom.url }/identity/connect/authorize` +
      `?client_id=${ environment.inqom.clientId }` +
      `&redirect_uri=${ encodeURIComponent( `${ window.location.origin }/authentication/inqom/callback` ) }` +
      `&response_type=code` +
      `&scope=${ encodeURIComponent( environment.inqom.scopes ) }` +
      `&state=${ state }` +
      `&code_challenge=${ codeChallenge }` +
      `&code_challenge_method=S256` +
      `&response_mode=query`;

    window.location.href = url;
  }

  /**
   * Create gitlab online repository from authorisation key
   * @param gitlabKey Authorisation key from gitlab
   */
  /*public createRepositoryFromAuthorisationKey(gitlabKey: string): Observable<ThirdPartsAuthenticationInqomCreateResponse> {
    const path = `/api/third-parts-authentication/inqom/retrieve-token?code=${ gitlabKey }`;
    return this.http.put<ThirdPartsAuthenticationInqomCreateResponse>(path, {});
  }*/


  /**
   * Save a state with an unique identifier returned
   * @param state state to store
   */
  public generateIdentifierAndCodeChallengeAndSaveState(callbackState: AuthenticationCallbackState): InqomState {
    // Generate identifier (toString(36) converts the number in base 36 (numbers + letters) and so reduce number size)
    const id = Math.random().toString(36);
    const state: InqomState = {
      id,
      state: callbackState,
      codeChallenge: Math.random().toString(36)
    }
    localStorage.setItem('inqomState', JSON.stringify(state));
    return state;
  }

  /**
   * Return the state saved for inqom
   * @param id id to check
   */
  public getState(): InqomState {
    const stringState = localStorage.getItem('inqomState');
    return stringState != null ? JSON.parse(stringState) : null;
  }

  public list$(): Observable<ThirdPartsAuthenticationList[]> {
    const path = `/api/third-parts/inqom/authentication/list`;
    return this.http.get<ThirdPartsAuthenticationList[]>(path);
  }

  public createOrUpdate$(request: ThirdPartsAuthenticationInqomManualCreateUpdateRequest) {
    const url = `/api/third-parts/inqom/authentication`;

    return this.http.post<void>(url, request);
  }
}
