import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { JournalOptionEnum, journalOptions } from '~modules/connectors/constants/journal.options';
import { ReverseChargeAggregateOptionEnum, reverseChargeAggregateOptions } from '~modules/connectors/constants/reverse-charge-aggregate.options';
import {
  defaultAccountLabelMaxLength,
  defaultAuxiliaryAccountMaxLength,
  defaultDynamicConfigMaxLength,
  defaultJournalCodeMaxLength,
  defaultJournalLabelMaxLength,
  defaultMainAccountMaxLength,
  defaultPourcentageMaxValue,
  defaultPourcentageMinValue,
  defaultPourcentagePatternValue,
  DescriptionOption,
  PaymentFieldsConfiguration
} from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { PaymentProcessorConfiguration } from '~modules/tools/extract-accounting-entries/entities/payment-processor-configuration.entity';
import { PaymentMethodConfigurationComponent } from '../payment-method-configuration/payment-method-configuration.component';
import { defaultDescriptionDynamicFieldOption, defaultDescriptionStandardFieldOption } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/default-transformation-fields';

@Component({
  selector: 'app-payment-processor-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PaymentMethodConfigurationComponent,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTooltipModule,
    TextFieldModule
  ],
  templateUrl: './payment-processor-configuration.component.html',
  styleUrls: ['./payment-processor-configuration.component.scss']
})
export class PaymentProcessorConfigurationComponent implements OnDestroy {

  reverseChargeAggregateOptions = reverseChargeAggregateOptions;
  journalOptions = journalOptions;

  formGroup!: FormGroup;

  hasDynamicClientSubscription?: Subscription;
  hasDynamicSupplierSubscription?: Subscription;
  hasReverseChargeSubscription?: Subscription;
  hasDescriptionTemplateSubscription?: Subscription;
  journalOptionSubscription?: Subscription;
  descriptionOptionSubscription?: Subscription;
  fieldsConfig: PaymentFieldsConfiguration | undefined;
  paymentConfig: PaymentProcessorConfiguration | undefined;

  @ViewChild('methods')
  set methods(value: PaymentMethodConfigurationComponent) {
    this.formGroup.setControl('methods', value?.formArray);
  }

  @Input()
  set paymentConfiguration (value: PaymentProcessorConfiguration | undefined) {
    if (value) {
      this.paymentConfig = value;
      // search correct option and then in case of dynamic label we can override default option value with the one from config
      this.formGroup.get('descriptionOption')?.setValue(this.findDescriptionOptionByDescriptionProperties(value.descriptionTemplate, value.hasDescriptionTemplate))
      this.formGroup.patchValue(value);
    }
  }

  @Input()
  toolLabel!: string | undefined;

  @Input()
  set fieldsConfiguration (value: PaymentFieldsConfiguration | undefined) {
    if (value != undefined) {
      this.fieldsConfig = value;

      const clientAccountMaxLength = value.client.account.maxLength != null ? value.client.account.maxLength : defaultMainAccountMaxLength;
      const clientAccountLabelMaxLength = value.client.label.maxLength != null ? value.client.label.maxLength : defaultAccountLabelMaxLength;
      const auxiliaryClientAccountStaticMaxLength = value.client.auxiliaryAccountStatic.maxLength != null ? value.client.auxiliaryAccountStatic.maxLength : defaultAuxiliaryAccountMaxLength;
      const clientAccountLabelStaticMaxLength = value.client.auxiliaryLabelStatic.maxLength != null ? value.client.auxiliaryLabelStatic.maxLength : defaultAccountLabelMaxLength;
      const auxiliaryClientAccountDynamicMaxLength = value.client.auxiliaryAccountDynamic.maxLength != null ? value.client.auxiliaryAccountDynamic.maxLength : defaultDynamicConfigMaxLength;
      const clientAccountLabelDynamicMaxLength = value.client.auxiliaryLabelDynamic.maxLength != null ? value.client.auxiliaryLabelDynamic.maxLength : defaultDynamicConfigMaxLength;
      const supplierAccountMaxLength = value.supplier.account.maxLength != null ? value.supplier.account.maxLength : defaultMainAccountMaxLength;
      const supplierAccountLabelMaxLength = value.supplier.label.maxLength != null ? value.supplier.label.maxLength : defaultAccountLabelMaxLength;
      const auxiliarySupplierAccountStaticMaxLength = value.supplier.auxiliaryAccountStatic.maxLength != null ? value.supplier.auxiliaryAccountStatic.maxLength : defaultAuxiliaryAccountMaxLength;
      const supplierAccountLabelStaticMaxLength = value.supplier.auxiliaryLabelStatic.maxLength != null ? value.supplier.auxiliaryLabelStatic.maxLength : defaultAccountLabelMaxLength;
      const auxiliarySupplierAccountDynamicMaxLength = value.supplier.auxiliaryAccountDynamic.maxLength != null ? value.supplier.auxiliaryAccountDynamic.maxLength : defaultDynamicConfigMaxLength;
      const supplierAccountLabelDynamicMaxLength = value.supplier.auxiliaryLabelDynamic.maxLength != null ? value.supplier.auxiliaryLabelDynamic.maxLength : defaultDynamicConfigMaxLength;
      const providerFeeAccountMaxLength = value.providerFee.account.maxLength != null ? value.providerFee.account.maxLength : defaultMainAccountMaxLength;
      const providerFeeAccountLabelMaxLength = value.providerFee.label.maxLength != null ? value.providerFee.label.maxLength : defaultAccountLabelMaxLength;
      const deductibleVat20AccountMaxLength = value.deductibleVat20.account.maxLength != null ? value.deductibleVat20.account.maxLength : defaultMainAccountMaxLength;
      const deductibleVat20AccountLabelMaxLength = value.deductibleVat20.label.maxLength != null ? value.deductibleVat20.label.maxLength : defaultAccountLabelMaxLength;
      const reverseChargeCollectedIntracomVatAccountMaxLength = value.reverseCharge.collectedIntracomVat.account.maxLength != null ? value.reverseCharge.collectedIntracomVat.account.maxLength : defaultMainAccountMaxLength;
      const reverseChargeCollectedIntracomVatAccountLabelMaxLength = value.reverseCharge.collectedIntracomVat.label.maxLength != null ? value.reverseCharge.collectedIntracomVat.label.maxLength : defaultAccountLabelMaxLength;
      const reverseChargeDeductibleIntracomVatAccountMaxLength = value.reverseCharge.deductibleIntracomVat.account.maxLength != null ? value.reverseCharge.deductibleIntracomVat.account.maxLength : defaultMainAccountMaxLength;
      const reverseChargeDeductibleIntracomVatAccountLabelMaxLength = value.reverseCharge.deductibleIntracomVat.label.maxLength != null ? value.reverseCharge.deductibleIntracomVat.label.maxLength : defaultAccountLabelMaxLength;
      const reverseChargeVatRateMinValue = value.reverseCharge.vatRate.minValue != null ? value.reverseCharge.vatRate.minValue : defaultPourcentageMinValue;
      const reverseChargeVatRateMaxValue = value.reverseCharge.vatRate.maxValue != null ? value.reverseCharge.vatRate.maxValue : defaultPourcentageMaxValue;
      const reverseChargeVatRatePatternValue = value.reverseCharge.vatRate.patternValue != null ? value.reverseCharge.vatRate.patternValue : defaultPourcentagePatternValue;
      const descriptionTemplateMaxLength = value.description.descriptionTemplate.maxLength != null ? value.description.descriptionTemplate.maxLength : defaultDynamicConfigMaxLength;
      this.formGroup.get('journalOption')?.setValue(value.journalOption.defaultValue);
      this.formGroup.get('clientAccount')?.setValue(value.client.account.defaultValue);
      this.formGroup.get('clientAccount')?.setValidators([Validators.required, Validators.maxLength(clientAccountMaxLength)]);
      this.formGroup.get('clientAccountLabel')?.setValue(value.client.label.defaultValue);
      this.formGroup.get('clientAccountLabel')?.setValidators([Validators.maxLength(clientAccountLabelMaxLength)]);
      this.formGroup.get('hasDynamicClient')?.setValue(value.client.isDynamic.defaultValue);
      this.formGroup.get('auxiliaryClientAccountStatic')?.setValidators([Validators.maxLength(auxiliaryClientAccountStaticMaxLength)]);
      this.formGroup.get('auxiliaryClientAccountLabelStatic')?.setValidators([Validators.maxLength(clientAccountLabelStaticMaxLength)]);
      this.formGroup.get('auxiliaryClientAccountDynamic')?.setValidators([Validators.maxLength(auxiliaryClientAccountDynamicMaxLength)]);
      this.formGroup.get('auxiliaryClientAccountLabelDynamic')?.setValidators([Validators.maxLength(clientAccountLabelDynamicMaxLength)]);
      if (value.client.isDynamic.defaultValue === true) {
        this.formGroup.get('auxiliaryClientAccountStatic')?.setValue(null);
        this.formGroup.get('auxiliaryClientAccountLabelStatic')?.setValue(null);
        this.formGroup.get('auxiliaryClientAccountDynamic')?.setValue(value.client.auxiliaryAccountDynamic.defaultValue);
        this.formGroup.get('auxiliaryClientAccountLabelDynamic')?.setValue(value.client.auxiliaryAccountDynamic.defaultValue);
      } else {
        this.formGroup.get('auxiliaryClientAccountStatic')?.setValue(value.client.auxiliaryAccountStatic.defaultValue);
        this.formGroup.get('auxiliaryClientAccountLabelStatic')?.setValue(value.client.auxiliaryAccountStatic.defaultValue);
        this.formGroup.get('auxiliaryClientAccountDynamic')?.setValue(null);
        this.formGroup.get('auxiliaryClientAccountLabelDynamic')?.setValue(null);
      }
      this.formGroup.get('supplierAccount')?.setValue(value.supplier.account.defaultValue);
      this.formGroup.get('supplierAccount')?.setValidators([Validators.required, Validators.maxLength(supplierAccountMaxLength)]);
      this.formGroup.get('supplierAccountLabel')?.setValue(value.supplier.label.defaultValue);
      this.formGroup.get('supplierAccountLabel')?.setValidators([Validators.maxLength(supplierAccountLabelMaxLength)]);
      this.formGroup.get('hasDynamicSupplier')?.setValue(value.supplier.isDynamic.defaultValue);
      this.formGroup.get('auxiliarySupplierAccountStatic')?.setValidators([Validators.maxLength(auxiliarySupplierAccountStaticMaxLength)]);
      this.formGroup.get('auxiliarySupplierAccountLabelStatic')?.setValidators([Validators.maxLength(supplierAccountLabelStaticMaxLength)]);
      this.formGroup.get('auxiliarySupplierAccountDynamic')?.setValidators([Validators.maxLength(auxiliarySupplierAccountDynamicMaxLength)]);
      this.formGroup.get('auxiliarySupplierAccountLabelDynamic')?.setValidators([Validators.maxLength(supplierAccountLabelDynamicMaxLength)]);
      if (value.supplier.isDynamic.defaultValue === true) {
        this.formGroup.get('auxiliarySupplierAccountStatic')?.setValue(null);
        this.formGroup.get('auxiliarySupplierAccountLabelStatic')?.setValue(null);
        this.formGroup.get('auxiliarySupplierAccountDynamic')?.setValue(value.supplier.auxiliaryAccountDynamic.defaultValue);
        this.formGroup.get('auxiliarySupplierAccountLabelDynamic')?.setValue(value.supplier.auxiliaryLabelDynamic.defaultValue);
      } else {
        this.formGroup.get('auxiliarySupplierAccountStatic')?.setValue(value.supplier.auxiliaryAccountStatic.defaultValue);
        this.formGroup.get('auxiliarySupplierAccountLabelStatic')?.setValue(value.supplier.auxiliaryLabelStatic.defaultValue);
        this.formGroup.get('auxiliarySupplierAccountDynamic')?.setValue(null);
        this.formGroup.get('auxiliarySupplierAccountLabelDynamic')?.setValue(null);
      }
      this.formGroup.get('providerFeeAccount')?.setValue(value.providerFee.account.defaultValue);
      this.formGroup.get('providerFeeAccount')?.setValidators([Validators.required, Validators.maxLength(providerFeeAccountMaxLength)]);
      this.formGroup.get('providerFeeAccountLabel')?.setValue(value.providerFee.label.defaultValue);
      this.formGroup.get('providerFeeAccountLabel')?.setValidators([Validators.maxLength(providerFeeAccountLabelMaxLength)]);
      this.formGroup.get('deductibleVat20Account')?.setValue(value.deductibleVat20.account.defaultValue);
      this.formGroup.get('deductibleVat20Account')?.setValidators([Validators.required, Validators.maxLength(deductibleVat20AccountMaxLength)]);
      this.formGroup.get('deductibleVat20AccountLabel')?.setValue(value.deductibleVat20.label.defaultValue);
      this.formGroup.get('deductibleVat20AccountLabel')?.setValidators([Validators.maxLength(deductibleVat20AccountLabelMaxLength)]);
      
      let defaultOption: DescriptionOption;
      let descriptionTemplateValue: string|null = null;
      if (value.description.defaultOptionValue == null) {
        defaultOption = defaultDescriptionStandardFieldOption;
      } else {
        defaultOption = value.description.options.find((option) => option.descriptionTemplateValue === value.description.defaultOptionValue) || defaultDescriptionDynamicFieldOption;
        descriptionTemplateValue = value.description.defaultOptionValue || defaultOption.descriptionTemplateValue || value.description.descriptionTemplate.defaultValue;
      }
      this.formGroup.get('descriptionOption')?.setValue(defaultOption);
      this.formGroup.get('hasDescriptionTemplate')?.setValue(defaultOption.hasDescriptionTemplate);
      this.formGroup.get('descriptionTemplate')?.setValidators([Validators.maxLength(descriptionTemplateMaxLength)]);
      this.formGroup.get('descriptionTemplate')?.setValue(descriptionTemplateValue);
      
      this.formGroup.get('hasReverseCharge')?.setValue(value.reverseCharge.has.defaultValue);
      if (value.reverseCharge.has.defaultValue === true) {
        this.formGroup.get('reverseChargeAggregateOption')?.setValue(value.reverseCharge.aggregateOption.defaultValue);
        this.formGroup.get('reverseChargeAggregateOption')?.setValidators([Validators.required]);
        this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.setValue(value.reverseCharge.collectedIntracomVat.account.defaultValue);
        this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.setValidators([Validators.required, Validators.maxLength(reverseChargeCollectedIntracomVatAccountMaxLength)]);
        this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.setValue(value.reverseCharge.collectedIntracomVat.label.defaultValue);
        this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.setValidators([Validators.maxLength(reverseChargeCollectedIntracomVatAccountLabelMaxLength)]);
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.setValue(value.reverseCharge.deductibleIntracomVat.account.defaultValue);
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.setValidators([Validators.required, Validators.maxLength(reverseChargeDeductibleIntracomVatAccountMaxLength)]);
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.setValue(value.reverseCharge.deductibleIntracomVat.label.defaultValue);
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.setValidators([Validators.maxLength(reverseChargeDeductibleIntracomVatAccountLabelMaxLength)]);
        this.formGroup.get('reverseChargeVatRate')?.setValue(value.reverseCharge.vatRate.defaultValue);
        this.formGroup.get('reverseChargeVatRate')?.setValidators([Validators.required, Validators.min(reverseChargeVatRateMinValue), Validators.max(reverseChargeVatRateMaxValue), Validators.pattern(reverseChargeVatRatePatternValue)]);
      } else {
        this.formGroup.get('reverseChargeAggregateOption')?.setValue(null);
        this.formGroup.get('reverseChargeAggregateOption')?.setValidators([]);
        this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.setValue(null);
        this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.setValidators([Validators.maxLength(reverseChargeCollectedIntracomVatAccountMaxLength)]);
        this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.setValue(null);
        this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.setValidators([Validators.maxLength(reverseChargeCollectedIntracomVatAccountLabelMaxLength)]);
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.setValue(null);
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.setValidators([Validators.maxLength(reverseChargeDeductibleIntracomVatAccountMaxLength)]);
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.setValue(null);
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.setValidators([Validators.maxLength(reverseChargeDeductibleIntracomVatAccountLabelMaxLength)]);
        this.formGroup.get('reverseChargeVatRate')?.setValue(null);
        this.formGroup.get('reverseChargeVatRate')?.setValidators([Validators.min(reverseChargeVatRateMinValue), Validators.max(reverseChargeVatRateMaxValue), Validators.pattern(reverseChargeVatRatePatternValue)]);
      }



      this.formGroup.markAllAsTouched();
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formGroup = new FormGroup({
      journalOption: new FormControl<JournalOptionEnum|null>(JournalOptionEnum.UNIQUE, [Validators.required]),
      uniqueJournalCode: new FormControl('', [Validators.required, Validators.maxLength(defaultJournalCodeMaxLength)]),
      uniqueJournalLabel: new FormControl('', [Validators.maxLength(defaultJournalLabelMaxLength)]),
      clientAccount: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      clientAccountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      hasDynamicClient: new FormControl(false, [Validators.required]),
      auxiliaryClientAccountStatic: new FormControl('', [Validators.maxLength(defaultAuxiliaryAccountMaxLength)]),
      auxiliaryClientAccountLabelStatic: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      auxiliaryClientAccountDynamic: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)]),
      auxiliaryClientAccountLabelDynamic: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)]),
      supplierAccount: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      supplierAccountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      hasDynamicSupplier: new FormControl(false, [Validators.required]),
      auxiliarySupplierAccountStatic: new FormControl('', [Validators.maxLength(defaultAuxiliaryAccountMaxLength)]),
      auxiliarySupplierAccountLabelStatic: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      auxiliarySupplierAccountDynamic: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)]),
      auxiliarySupplierAccountLabelDynamic: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)]),
      providerFeeAccount: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      providerFeeAccountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      deductibleVat20Account: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      deductibleVat20AccountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      descriptionOption: new FormControl(null),
      hasDescriptionTemplate: new FormControl(false, [Validators.required]),
      descriptionTemplate: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)]),
      hasReverseCharge: new FormControl(false, []),
      reverseChargeAggregateOption: new FormControl(null, []),
      reverseChargeCollectedIntracomVatAccount: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      reverseChargeCollectedIntracomVatAccountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      reverseChargeDeductibleIntracomVatAccount: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      reverseChargeDeductibleIntracomVatAccountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      reverseChargeVatRate: new FormControl('', [Validators.min(defaultPourcentageMinValue), Validators.max(defaultPourcentageMaxValue), Validators.pattern(defaultPourcentagePatternValue)])
    })

    this.hasDynamicClientSubscription = this.formGroup.get('hasDynamicClient')?.valueChanges.subscribe({
      next: (value) => {
        if (value === true) {
          // put to null static fields
          this.formGroup.get('auxiliaryClientAccountStatic')?.setValue(null);
          this.formGroup.get('auxiliaryClientAccountLabelStatic')?.setValue(null);

          // update auxiliary account default value
          if (this.formGroup.get('auxiliaryClientAccountDynamic')?.getRawValue() === null) {
            const defaultAuxiliaryClientAccountDynamic = this.fieldsConfig?.client?.auxiliaryAccountDynamic?.defaultValue || `{{ thirdParty.mainName | normalize | upper | left(8) }}`;
            this.formGroup.get('auxiliaryClientAccountDynamic')?.setValue(defaultAuxiliaryClientAccountDynamic);
          }

          // update label default value
          if (this.formGroup.get('auxiliaryClientAccountLabelDynamic')?.getRawValue() === null) {
            const defaultClientAccountLabelDynamic = this.fieldsConfig?.client?.auxiliaryLabelDynamic?.defaultValue || `{{ thirdParty.mainName }}`;
            this.formGroup.get('auxiliaryClientAccountLabelDynamic')?.setValue(defaultClientAccountLabelDynamic);
          }
        } else {
          // put to null dynamic fields
          this.formGroup.get('auxiliaryClientAccountDynamic')?.setValue(null);
          this.formGroup.get('auxiliaryClientAccountLabelDynamic')?.setValue(null);
          
          // update auxiliary account default value
          if (this.formGroup.get('auxiliaryClientAccountStatic')?.getRawValue() === null) {
            const defaultAuxiliaryClientAccountStatic = this.fieldsConfig?.client?.auxiliaryAccountStatic?.defaultValue || this.toolLabel;
            this.formGroup.get('auxiliaryClientAccountStatic')?.setValue(defaultAuxiliaryClientAccountStatic);
          }

          // update label default value
          if (this.formGroup.get('auxiliaryClientAccountLabelStatic')?.getRawValue() === null) {
            const defaultClientAccountLabelStatic = this.fieldsConfig?.client?.auxiliaryLabelStatic?.defaultValue || this.toolLabel;
            this.formGroup.get('auxiliaryClientAccountLabelStatic')?.setValue(defaultClientAccountLabelStatic);
          }
        }

        // validate form
        this.formGroup.get('auxiliaryClientAccountStatic')?.updateValueAndValidity();
        this.formGroup.get('auxiliaryClientAccountLabelStatic')?.updateValueAndValidity();
        this.formGroup.get('auxiliaryClientAccountDynamic')?.updateValueAndValidity();
        this.formGroup.get('auxiliaryClientAccountLabelDynamic')?.updateValueAndValidity();
      }
    });

    this.hasDynamicSupplierSubscription = this.formGroup.get('hasDynamicSupplier')?.valueChanges.subscribe({
      next: (value) => {
        if (value === true) {
          // put to null static fields
          this.formGroup.get('auxiliarySupplierAccountStatic')?.setValue(null);
          this.formGroup.get('auxiliarySupplierAccountLabelStatic')?.setValue(null);

          // update auxiliary account default value
          if (this.formGroup.get('auxiliarySupplierAccountDynamic')?.getRawValue() === null) {
            const defaultAuxiliarySupplierAccountDynamic = this.fieldsConfig?.supplier?.auxiliaryAccountDynamic?.defaultValue || `{{ thirdParty.mainName | normalize | upper | left(8) }}`;
            this.formGroup.get('auxiliarySupplierAccountDynamic')?.setValue(defaultAuxiliarySupplierAccountDynamic);
          }

          // update label default value
          if (this.formGroup.get('auxiliarySupplierAccountLabelDynamic')?.getRawValue() === null) {
            const defaultSupplierAccountLabelDynamic = this.fieldsConfig?.supplier?.auxiliaryLabelDynamic?.defaultValue || `{{ thirdParty.mainName }}`;
            this.formGroup.get('auxiliarySupplierAccountLabelDynamic')?.setValue(defaultSupplierAccountLabelDynamic);
          }
        } else {
          // put to null dynamic fields
          this.formGroup.get('auxiliarySupplierAccountDynamic')?.setValue(null);
          this.formGroup.get('auxiliarySupplierAccountLabelDynamic')?.setValue(null);
          
          // update auxiliary account default value
          if (this.formGroup.get('auxiliarySupplierAccountStatic')?.getRawValue() === null) {
            const defaultAuxiliarySupplierAccountStatic = this.fieldsConfig?.supplier?.auxiliaryAccountStatic?.defaultValue || this.toolLabel;
            this.formGroup.get('auxiliarySupplierAccountStatic')?.setValue(defaultAuxiliarySupplierAccountStatic);
          }

          // update label default value
          if (this.formGroup.get('auxiliarySupplierAccountLabelStatic')?.getRawValue() === null) {
            const defaultSupplierAccountLabelStatic = this.fieldsConfig?.supplier?.auxiliaryLabelStatic?.defaultValue || this.toolLabel;
            this.formGroup.get('auxiliarySupplierAccountLabelStatic')?.setValue(defaultSupplierAccountLabelStatic);
          }
        }

        // validate form
        this.formGroup.get('auxiliarySupplierAccountLabelStatic')?.updateValueAndValidity();
        this.formGroup.get('auxiliarySupplierAccountStatic')?.updateValueAndValidity();
        this.formGroup.get('auxiliarySupplierAccountDynamic')?.updateValueAndValidity();
        this.formGroup.get('auxiliarySupplierAccountLabelDynamic')?.updateValueAndValidity();
      }
    });

    this.hasReverseChargeSubscription = this.formGroup.get('hasReverseCharge')?.valueChanges.subscribe({
      next: (value) => {
        const reverseChargeCollectedIntracomVatAccountMaxLength = this.fieldsConfig?.reverseCharge?.collectedIntracomVat?.account?.maxLength != null ? this.fieldsConfig.reverseCharge.collectedIntracomVat.account.maxLength : defaultMainAccountMaxLength;
        const reverseChargeCollectedIntracomVatAccountLabelMaxLength = this.fieldsConfig?.reverseCharge?.collectedIntracomVat?.label?.maxLength != null ? this.fieldsConfig.reverseCharge.collectedIntracomVat.label?.maxLength : defaultAccountLabelMaxLength;
        const reverseChargeDeductibleIntracomVatAccountMaxLength = this.fieldsConfig?.reverseCharge?.deductibleIntracomVat?.account?.maxLength != null ? this.fieldsConfig.reverseCharge.deductibleIntracomVat.account.maxLength : defaultMainAccountMaxLength;
        const reverseChargeDeductibleIntracomVatAccountLabelMaxLength = this.fieldsConfig?.reverseCharge?.deductibleIntracomVat?.label?.maxLength != null ? this.fieldsConfig.reverseCharge.deductibleIntracomVat.label?.maxLength : defaultAccountLabelMaxLength;
        const reverseChargeVatRateMinValue = this.fieldsConfig?.reverseCharge?.vatRate?.minValue != null ? this.fieldsConfig.reverseCharge.vatRate.minValue : defaultPourcentageMinValue;
        const reverseChargeVatRateMaxValue = this.fieldsConfig?.reverseCharge?.vatRate?.maxValue != null ? this.fieldsConfig.reverseCharge.vatRate.maxValue : defaultPourcentageMaxValue;
        const reverseChargeVatRatePatternValue = this.fieldsConfig?.reverseCharge?.vatRate?.patternValue != null ? this.fieldsConfig.reverseCharge.vatRate.patternValue : defaultPourcentagePatternValue;

        if (value === true) {
          // update validators
          this.formGroup.get('reverseChargeAggregateOption')?.setValidators([Validators.required]);
          this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.setValidators([Validators.required, Validators.maxLength(reverseChargeCollectedIntracomVatAccountMaxLength)]);
          this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.setValidators([Validators.maxLength(reverseChargeCollectedIntracomVatAccountLabelMaxLength)]);
          this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.setValidators([Validators.required, Validators.maxLength(reverseChargeDeductibleIntracomVatAccountMaxLength)]);
          this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.setValidators([Validators.maxLength(reverseChargeDeductibleIntracomVatAccountLabelMaxLength)]);
          this.formGroup.get('reverseChargeVatRate')?.setValidators([Validators.required, Validators.min(reverseChargeVatRateMinValue), Validators.max(reverseChargeVatRateMaxValue), Validators.pattern(reverseChargeVatRatePatternValue)]);


          // update values
          if(this.formGroup.get('reverseChargeAggregateOption')?.getRawValue() === null) {
            this.formGroup.get('reverseChargeAggregateOption')?.setValue(this.fieldsConfig?.reverseCharge?.aggregateOption?.defaultValue || null);
          }
          if(this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.getRawValue() === null) {
            this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.setValue(this.fieldsConfig?.reverseCharge?.collectedIntracomVat?.account?.defaultValue || null);
          }
          if(this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.getRawValue() === null) {
            this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.setValue(this.fieldsConfig?.reverseCharge?.collectedIntracomVat?.label?.defaultValue || null);
          }
          if(this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.getRawValue() === null) {
            this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.setValue(this.fieldsConfig?.reverseCharge?.deductibleIntracomVat?.account?.defaultValue || null);
          }
          if(this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.getRawValue() === null) {
            this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.setValue(this.fieldsConfig?.reverseCharge?.deductibleIntracomVat?.label?.defaultValue || null);
          }
          if(this.formGroup.get('reverseChargeVatRate')?.getRawValue() === null) {
            this.formGroup.get('reverseChargeVatRate')?.setValue(this.fieldsConfig?.reverseCharge?.vatRate?.defaultValue || null);
          }
        } else {
          // update validators
          this.formGroup.get('reverseChargeAggregateOption')?.setValidators([]);
          this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.setValidators([Validators.maxLength(reverseChargeCollectedIntracomVatAccountMaxLength)]);
          this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.setValidators([Validators.maxLength(reverseChargeCollectedIntracomVatAccountLabelMaxLength)]);
          this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.setValidators([Validators.maxLength(reverseChargeDeductibleIntracomVatAccountMaxLength)]);
          this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.setValidators([Validators.maxLength(reverseChargeDeductibleIntracomVatAccountLabelMaxLength)]);
          this.formGroup.get('reverseChargeVatRate')?.setValidators([Validators.min(reverseChargeVatRateMinValue), Validators.max(reverseChargeVatRateMaxValue), Validators.pattern(reverseChargeVatRatePatternValue)]);

          // update values
          this.formGroup.get('reverseChargeAggregateOption')?.setValue(null);
          this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.setValue(null);
          this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.setValue(null);
          this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.setValue(null);
          this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.setValue(null);
          this.formGroup.get('reverseChargeVatRate')?.setValue(null);
        }

        // validate form
        this.formGroup.get('reverseChargeAggregateOption')?.updateValueAndValidity();
        this.formGroup.get('reverseChargeCollectedIntracomVatAccount')?.updateValueAndValidity();
        this.formGroup.get('reverseChargeCollectedIntracomVatAccountLabel')?.updateValueAndValidity();
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccount')?.updateValueAndValidity();
        this.formGroup.get('reverseChargeDeductibleIntracomVatAccountLabel')?.updateValueAndValidity();
        this.formGroup.get('reverseChargeVatRate')?.updateValueAndValidity();
      }
    });

    this.descriptionOptionSubscription = this.formGroup.get('descriptionOption')?.valueChanges.subscribe({
      next: (value) => {
        this.formGroup.get('hasDescriptionTemplate')?.setValue(value.hasDescriptionTemplate);
        if (value.hasDescriptionTemplate === true) {
          this.formGroup.get('descriptionTemplate')?.setValue(
            value.descriptionTemplateValue ? value.descriptionTemplateValue : this.fieldsConfig?.description.descriptionTemplate.defaultValue
          );
        } else {
          this.formGroup.get('descriptionTemplate')?.setValue(null);
        }
        this.formGroup.get('descriptionTemplate')?.updateValueAndValidity();
      }
    });

    this.journalOptionSubscription = this.formGroup.get('journalOption')?.valueChanges.subscribe({
      next: (value) => {
        const uniqueJournalCodeMaxLength = this.fieldsConfig?.uniqueJournal.code.maxLength != null ? this.fieldsConfig?.uniqueJournal.code.maxLength : defaultJournalCodeMaxLength;
        const uniqueJournalLabelMaxLength = this.fieldsConfig?.uniqueJournal.label.maxLength != null ? this.fieldsConfig?.uniqueJournal.label.maxLength : defaultJournalLabelMaxLength;

        if (value === JournalOptionEnum.UNIQUE) {
          this.formGroup.get('uniqueJournalCode')?.setValue(this.fieldsConfig?.uniqueJournal.code.defaultValue);
          this.formGroup.get('uniqueJournalCode')?.setValidators([Validators.required, Validators.maxLength(uniqueJournalCodeMaxLength)]);
          this.formGroup.get('uniqueJournalLabel')?.setValue(this.fieldsConfig?.uniqueJournal.label.defaultValue);
          this.formGroup.get('uniqueJournalLabel')?.setValidators([Validators.maxLength(uniqueJournalLabelMaxLength)]);
        } else {
          this.formGroup.get('uniqueJournalCode')?.setValue(null);
          this.formGroup.get('uniqueJournalCode')?.setValidators([]);
          this.formGroup.get('uniqueJournalLabel')?.setValue(null);
          this.formGroup.get('uniqueJournalLabel')?.setValidators([]);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.hasDynamicClientSubscription?.unsubscribe();
    this.hasDynamicSupplierSubscription?.unsubscribe();
    this.hasReverseChargeSubscription?.unsubscribe();
    this.hasDescriptionTemplateSubscription?.unsubscribe();
    this.journalOptionSubscription?.unsubscribe();
    this.descriptionOptionSubscription?.unsubscribe();
  }

  compareFunction(o1: ReverseChargeAggregateOptionEnum|JournalOptionEnum, o2: ReverseChargeAggregateOptionEnum|JournalOptionEnum) {
    return (o1 == null && o2 == null) || (o1 != null && o2 != null && o1 === o2);
  }

  compareSelectionObjects(object1: DescriptionOption, object2: DescriptionOption) {
    return object1 && object2 && object1.label == object2.label;
  }

  avoidLineBreak(event: KeyboardEvent) {
    if(event.key == 'Enter' || (event.shiftKey && event.key == 'Enter')) {
      event.preventDefault();
    }
  }

  findDescriptionOptionByDescriptionProperties(descriptionTemplate: string, hasDescriptionTemplate: boolean): DescriptionOption | undefined {
    if (hasDescriptionTemplate === false) {
      return defaultDescriptionStandardFieldOption;
    }
    const result = this.fieldsConfig?.description.options.find((option => option.descriptionTemplateValue === descriptionTemplate));
    if (result) {
      return result;
    } else {
      return defaultDescriptionDynamicFieldOption;
    }
  }

}