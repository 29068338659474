import { Injectable } from "@angular/core";
import { AccountingFolderEntity, CreateAccountingFolderEntity } from "../entities/accounting-folder.entity";
import { Observable, take } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AccountingFoldersService {

    static getPath(): string {
        return `/api/accounting-folders`;
    }

    constructor(
        private readonly http: HttpClient,
    ) {}

    getAll$(): Observable<AccountingFolderEntity[]> {
        return this.http
            .get<AccountingFolderEntity[]>(`${AccountingFoldersService.getPath()}/`)
            .pipe(take(1));
    }

    getOne$(
        id: string
    ): Observable<AccountingFolderEntity> {

        return this.http
            .get<AccountingFolderEntity>(`${AccountingFoldersService.getPath()}/${id}`)
            .pipe(take(1));
    }

    create$(
        dto: CreateAccountingFolderEntity
    ): Observable<AccountingFolderEntity> {
        return this.http
            .put<AccountingFolderEntity>(AccountingFoldersService.getPath(), dto)
            .pipe(take(1));
    }

    edit$(
        dto: AccountingFolderEntity
    ): Observable<AccountingFolderEntity> {

        const url = `${AccountingFoldersService.getPath()}/${dto.id}`
        return this.http
            .put<AccountingFolderEntity>(url, dto)
            .pipe(take(1));
    }

    delete$(
        id: string
    ): Observable<void> {
        return this.http
            .delete<void>(`${AccountingFoldersService.getPath()}/${id}`)
            .pipe(take(1));
    }
}