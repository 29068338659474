import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoalaExtractRequest } from '../entities/coala-extract-request.entity';

@Injectable()
export class CoalaFormatService {

  constructor(
      private http: HttpClient,
  ) { }

  extractAccountingAssets$(
    remoteFileId: string, 
    outputFormat: string
  ): Observable<Blob> {
    const url = `/api/tools/accounting-assets/extract`;

    const data: CoalaExtractRequest = {
      outputFormat,
      remoteFileId
    };

    return this.http.post(url, data, {responseType: 'blob'});
  }
}
