import { Injectable } from "@angular/core";
import { LoadConnectorType } from "../models/load-connector.type";
import { LoadNodeEnum } from '~modules/connectors/dtos/create-update-global-connector.request';
import { LoadDexDownloadConfigurationComponent } from "../modules/load/dex-download/components/load-dex-download-configuration/load-dex-download-configuration.component";
import { LoadAsciiDownloadConfigurationComponent } from "../modules/load/ascii-download/components/load-ascii-download-configuration/load-ascii-download-configuration.component";
import { LoadInqomFecDownloadConfigurationComponent } from "../modules/load/inqom-fec-download/components/load-inqom-fec-download-configuration/load-inqom-fec-download-configuration.component";
import { LoadFecDownloadConfigurationComponent } from "../modules/load/fec-download/components/load-fec-download-configuration/load-fec-download-configuration.component";
import { LoadEcrDownloadConfigurationComponent } from "../modules/load/ecr-download/components/load-ecr-download-configuration/load-ecr-download-configuration.component";
import { LoadInqomZipFecDownloadConfigurationComponent } from "../modules/load/inqom-zip-fec-download/components/load-inqom-zip-fec-download-configuration/load-inqom-zip-fec-download-configuration.component";
import { Observable, take, switchMap, of } from 'rxjs';
import { CurrentCompanyService } from "~modules/company/services/current-company.service";
import { LoadZipAsciiDownloadConfigurationComponent } from "../modules/load/zip-ascii-download/components/load-ascii-download-configuration/load-zip-ascii-download-configuration.component";

export const loadConnectorsNodeList: LoadConnectorType[] = [
    {
        configurationComponent: LoadDexDownloadConfigurationComponent,
        id: 'dex_dl',
        iconUrl: '/assets/img/connectors/load-nodes/agiris-dex-pj_300x300.png',
        label: 'DEX (ECR avec Pièces Jointes)',
        softwares: ['Agiris', 'Isacompta', 'Isagri'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        type: 'DEX_DOWNLOAD'
    },
    {
        configurationComponent: LoadEcrDownloadConfigurationComponent,
        id: 'ecr_dl',
        iconUrl: '/assets/img/connectors/load-nodes/agiris-ecr_300x300.png',
        label: 'ECR',
        softwares: ['Agiris', 'Isacompta', 'Isagri'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        type: 'ECR_DOWNLOAD'
    },
    {
        configurationComponent: LoadAsciiDownloadConfigurationComponent,
        id: 'ascii_dl',
        iconUrl: '/assets/img/connectors/load-nodes/quadratus_300x300.png',
        label: 'ASCII (sans Pièces Jointes)',
        softwares: ['Quadratus'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        type: 'ASCII_DOWNLOAD'
    },
    {
        configurationComponent: LoadZipAsciiDownloadConfigurationComponent,
        id: 'ascii_dl',
        iconUrl: '/assets/img/connectors/load-nodes/quadratus-zip_300x300.png',
        label: 'ASCII ZIP (avec Pièces Jointes)',
        softwares: ['Quadratus'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        type: 'ZIP_ASCII_DOWNLOAD'
    },
    {
        configurationComponent: LoadInqomFecDownloadConfigurationComponent,
        id: 'inqom_fec_dl',
        iconUrl: '/assets/img/connectors/load-nodes/inqom-fec_300x300.png',
        label: 'Inqom FEC avec analytique',
        softwares: ['Inqom'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        type: 'INQOM_FEC_DOWNLOAD'
    },
    {
        configurationComponent: LoadInqomZipFecDownloadConfigurationComponent,
        id: 'inqom_zip_fec_dl',
        iconUrl: '/assets/img/connectors/load-nodes/inqom-zip_300x300.png',
        label: 'Inqom ZIP (FEC, pièces et analytique)',
        softwares: ['Inqom'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        type: 'INQOM_ZIP_FEC_DOWNLOAD'
    },
    {
        configurationComponent: LoadFecDownloadConfigurationComponent,
        id: 'fec_dl',
        iconUrl: '/assets/img/connectors/load-nodes/file-download_300x300.png',
        label: 'FEC',
        softwares: [],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        type: 'FEC_DOWNLOAD'
    }
];

@Injectable()
export class LoadConnectorsService {

    constructor(
        private readonly currentCompanyService: CurrentCompanyService
    ) {}

    getOneByType(type: LoadNodeEnum): LoadConnectorType | null {
        const connector = this.getList().find(element => element.type === type);
        if (connector == null) {
            return null;
        }
        return connector;
    }

    getAvailableOnes$(): Observable<LoadConnectorType[]> {
        return this.currentCompanyService.currentConnectorsIncludedLoadNodes$.pipe(
            take(1),
            switchMap(
                nodeTypes => of(this.getList().filter((tool) => nodeTypes.includes(tool.type)).sort((a, b) => a.label.localeCompare(b.label)))
            )
        );
    }

    getList(): LoadConnectorType[] {
        return loadConnectorsNodeList;
    }
}
