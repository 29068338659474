import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { AnalyticProcessorConfiguration } from '../../entities/analytic-processor-configuration.entity';
import { AnalyticAccountRuleConfigurationComponent } from '../analytic-account-rule-processor-configuration/analytic-account-rule-configuration.component';
import { AnalyticFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';

@Component({
  selector: 'app-analytic-processor-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AnalyticAccountRuleConfigurationComponent,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDividerModule
  ],
  templateUrl: './analytic-processor-configuration.component.html',
  styleUrls: ['./analytic-processor-configuration.component.scss']
})
export class AnalyticProcessorConfigurationComponent {

  formGroup!: FormGroup;

  fieldsConfig: AnalyticFieldsConfiguration | undefined;
  analyticConfig: AnalyticProcessorConfiguration | undefined

  @Input()
  toolLabel!: string | undefined;

  @ViewChild('accountRules')
  set accountRulesComponent(value: AnalyticAccountRuleConfigurationComponent) {
    this.formGroup.setControl('accountRules', value?.formArray);
  }

  @Input()
  set analyticConfiguration (value: AnalyticProcessorConfiguration | undefined) {
    if (value) {
      this.analyticConfig = value;
      this.formGroup.patchValue(value);
    }
  }

  @Input()
  set fieldsConfiguration (value: AnalyticFieldsConfiguration | undefined) {
    if (value != undefined) {
      this.fieldsConfig = value;

      this.formGroup.markAllAsTouched();
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formGroup = new FormGroup({
      isEnabled: new FormControl(false, [Validators.required])
    });
  }
}