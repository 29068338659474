<form [formGroup]="formGroup" *ngIf="invoiceDirection$ | async as invoiceDirection">
  <fieldset *ngIf="fieldsConfig?.journal?.visible !== false">
    <legend>Journal</legend>
    <mat-form-field class="third-width" *ngIf="fieldsConfig?.journal?.code?.visible !== false">
      <mat-label>Code Journal</mat-label>
      <input matInput formControlName="journalCode" [placeholder]="fieldsConfig?.journal?.code?.defaultValue || ''">
    </mat-form-field>
    <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.journal?.label?.visible !== false">
      <mat-label>Libellé Journal</mat-label>
      <input matInput formControlName="journalLabel" [placeholder]="fieldsConfig?.journal?.label?.defaultValue || ''">
    </mat-form-field>
  </fieldset>

  <fieldset *ngIf="fieldsConfig?.thirdPartyAccount?.visible !== false">
    <legend>{{ invoiceDirection === 'SELL' ? 'Tiers (clients)' : 'Tiers (fournisseurs)' }}</legend>
    <app-invoice-third-party-processor-configuration
      [fieldsConfiguration]="fieldsConfig?.thirdPartyAccount"
      [thirdPartyAccount]="invoiceConfig?.thirdPartyAccount"
      [toolLabel]="toolLabel"
      [thirdPartyType]="invoiceDirection === 'SELL' ? 'CLIENT' : 'SUPPLIER'"
      #thirdPartyAccount
  ></app-invoice-third-party-processor-configuration>
  </fieldset>

  <fieldset *ngIf="fieldsConfig?.generation?.visible !== false || fieldsConfig?.generation?.useSuggestedProductsAccounts?.visible !== false">
    <legend>{{ invoiceDirection === 'SELL' ? 'Vente' : 'Achat' }}</legend>

    <div class="row" *ngIf="fieldsConfig?.generation?.useSuggestedProductsAccounts?.visible !== false">
      <mat-slide-toggle formControlName="useSuggestedProductsAccounts">
        <div class="text-and-icon-container">
          <span>Reprendre la configuration comptable {{ toolLabel }} des comptes de {{ invoiceDirection === 'SELL' ? 'produits' : 'charges' }}</span>&nbsp;
        </div>
      </mat-slide-toggle>
    </div>

    <div class="row" *ngIf="fieldsConfig?.generation?.option?.visible !== false">
      <mat-form-field class="full-width">
        <mat-label>Répartition des lignes de {{ invoiceDirection === 'SELL' ? 'produit' : 'charge' }}</mat-label>
        <mat-select formControlName="generationOption" [compareWith]="compareFunction">
          <mat-option *ngFor="let option of invoiceGenerationOptions" [value]="option.value">{{option.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <div class="row danger-info" *ngIf="formGroup.value.useSuggestedProductsAccounts === true">
      <small>Si les comptes comptables ne sont pas configurés dans Axonaut, les comptes suivants seront utilisés par défaut.</small>
    </div>

    <ng-container *ngIf="formGroup.value.generationOption === 'ONE_LINE_PER_VAT_RATE'">
      <app-vat-account-configuration
        [fieldsConfiguration]="fieldsConfig?.generation?.oneLinePerVatRate?.productVats"
        [vatAccounts]="invoiceConfig?.productVatAccounts"
        [toolLabel]="toolLabel"
        [addLabel]="invoiceDirection === 'SELL' ? 'Ajouter un compte de produit': 'Ajouter un compte de charge'"
        [duplicateErrorLabel]="invoiceDirection === 'SELL' ?
         'Deux lignes de compte de produits ne peuvent pas être identiques' :
         'Deux lignes de compte de charges ne peuvent pas être identiques'"
        #productVatAccounts
      ></app-vat-account-configuration>
    </ng-container>
    <ng-container *ngIf="formGroup.value.generationOption === 'ONE_LINE_PER_INVOICE_LINE' || formGroup.value.generationOption === 'ONE_LINE_PER_PRODUCT_ACCOUNT'">
      <app-vat-account-configuration
        [fieldsConfiguration]="fieldsConfig?.generation?.oneLinePerInvoiceLine?.productVats"
        [vatAccounts]="invoiceConfig?.productVatAccounts"
        [toolLabel]="toolLabel"
        [addLabel]="invoiceDirection === 'SELL' ? 'Ajouter un compte de produit': 'Ajouter un compte de charge'"
        [duplicateErrorLabel]="invoiceDirection === 'SELL' ?
        'Deux lignes de compte de produits ne peuvent pas être identiques' :
        'Deux lignes de compte de charges ne peuvent pas être identiques'"
        #productVatAccounts
      ></app-vat-account-configuration>
    </ng-container>
    <ng-container *ngIf="formGroup.value.generationOption === 'ONE_GLOBAL_LINE'">
      <div class="row" *ngIf="fieldsConfig?.generation?.oneGlobalLine?.product?.visible !== false">
        <mat-form-field class="third-width" *ngIf="fieldsConfig?.generation?.oneGlobalLine?.product?.account?.visible !== false">
          <mat-label>Produit</mat-label>
          <input matInput formControlName="productAccount" [placeholder]="fieldsConfig?.generation?.oneGlobalLine?.product?.account?.defaultValue || ''">
        </mat-form-field>
        <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.generation?.oneGlobalLine?.product?.label?.visible !== false">
          <mat-label>Libellé</mat-label>
          <input matInput formControlName="productAccountLabel" [placeholder]="fieldsConfig?.generation?.oneGlobalLine?.product?.label?.defaultValue || ''">
        </mat-form-field>
      </div>
    </ng-container>

    <mat-divider></mat-divider>

    <div class="row" *ngIf="fieldsConfig?.useSuggestedVatsAccounts?.visible !== false">
      <mat-slide-toggle formControlName="useSuggestedVatsAccounts">
        <div class="text-and-icon-container">
          <span>Reprendre la configuration comptable {{ toolLabel }} des comptes de TVA</span>&nbsp;
        </div>
      </mat-slide-toggle>
    </div>
    
    <div class="row danger-info" *ngIf="formGroup.value.useSuggestedVatsAccounts === true">
      <small>Si les comptes comptables ne sont pas configurés dans Axonaut, les comptes suivants seront utilisés par défaut.</small>
    </div>

    <app-vat-account-configuration
      [fieldsConfiguration]="fieldsConfig?.collectedVats"
      [vatAccounts]="invoiceConfig?.collectedVatAccounts"
      [toolLabel]="toolLabel"
      addLabel="Ajouter un compte de TVA"
      duplicateErrorLabel="Deux lignes de compte de TVA ne peuvent pas être identiques"
      #collectedVatAccounts
    ></app-vat-account-configuration>

    <mat-divider *ngIf="fieldsConfig?.amountDifferenceOption?.visible !== false"></mat-divider>

    <div class="row" *ngIf="fieldsConfig?.amountDifferenceOption?.visible !== false">
      <mat-form-field class="full-width">
        <mat-label>Gestion des écarts HT/TVA/TTC</mat-label>
        <mat-select formControlName="amountDifferenceOption" [compareWith]="compareFunction">
          <mat-option *ngFor="let option of invoiceAmountDifferenceOptions" [value]="option.value">{{option.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row" *ngIf="formGroup.value.amountDifferenceOption === 'USE_ACCOUNTS' && fieldsConfig?.debitAdjustment?.visible !== false">
      <mat-form-field class="third-width" *ngIf="fieldsConfig?.debitAdjustment?.account?.visible !== false">
        <mat-label>Charges diverses de gestion courante</mat-label>
        <input matInput formControlName="debitAdjustmentAccount" [placeholder]="fieldsConfig?.debitAdjustment?.account?.defaultValue || ''">
      </mat-form-field>
      <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.debitAdjustment?.label?.visible !== false">
        <mat-label>Libellé charges diverses de gestion courante</mat-label>
        <input matInput formControlName="debitAdjustmentAccountLabel" [placeholder]="fieldsConfig?.debitAdjustment?.label?.defaultValue || ''">
      </mat-form-field>
    </div>
    <div class="row" *ngIf="formGroup.value.amountDifferenceOption === 'USE_ACCOUNTS' && fieldsConfig?.creditAdjustment?.visible !== false">
      <mat-form-field class="third-width" *ngIf="fieldsConfig?.creditAdjustment?.account?.visible !== false">
        <mat-label>Produits divers de gestion courante</mat-label>
        <input matInput formControlName="creditAdjustmentAccount" [placeholder]="fieldsConfig?.creditAdjustment?.account?.defaultValue || ''">
      </mat-form-field>
      <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.creditAdjustment?.label?.visible !== false">
        <mat-label>Libellé produits divers de gestion courante</mat-label>
        <input matInput formControlName="creditAdjustmentAccountLabel" [placeholder]="fieldsConfig?.creditAdjustment?.label?.defaultValue || ''">
      </mat-form-field>
    </div>
  </fieldset>

  <fieldset *ngIf="fieldsConfig?.description?.visible !== false">
    <legend>Personnalisation des écritures</legend>
    <div class="row">
      <mat-form-field class="full-width">
        <mat-label>Options de personnalisation</mat-label>
        <mat-select  formControlName="descriptionOption"
        [compareWith]="compareSelectionObjects">
          <mat-option *ngFor="let option of fieldsConfig?.description?.options" [value]="option">{{option.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field *ngIf="this.formGroup.get('descriptionOption')?.value.showDescriptionTemplateField === true && fieldsConfig?.description?.descriptionTemplate?.visible !== false" class="full-width">
        <mat-label>Libellé d'écriture personnalisé</mat-label>
        <textarea matInput
        (keypress)="avoidLineBreak($event)"
        formControlName="descriptionTemplate"
        cdkTextareaAutosize
        #clientAccountLabelAutosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5">
        </textarea>
      </mat-form-field>
    </div>
  </fieldset>
</form>