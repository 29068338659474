import { InvoiceGenerationOptionEnum } from '~modules/connectors/constants/Invoice-generation.option';
import { InvoiceAmountDifferenceOptionEnum } from '~modules/connectors/constants/invoice-amount-difference.option';
import { JournalOptionEnum } from '~modules/connectors/constants/journal.options';
import { ReverseChargeAggregateOptionEnum } from '~modules/connectors/constants/reverse-charge-aggregate.options';
import { AccountingEntriesFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { getPaymentDescriptionFieldOptions, getSellInvoiceDescriptionFieldOptions } from './default-transformation-fields';

const shopifyPaymentDescriptionOptions: DescriptionOption[] = getPaymentDescriptionFieldOptions({
    withThirdPart: true
});

const shopifySellInvoiceDescriptionOptions: DescriptionOption[] = getSellInvoiceDescriptionFieldOptions({
    withThirdPart: true
});

export const shopifyTransformationFieldsConfiguration: AccountingEntriesFieldsConfiguration = {
    toolLabel: 'Shopify',

    paymentProcessorConfiguration: {
        journalOption: {
            defaultValue: JournalOptionEnum.BY_PAYMENT_METHOD
        },
        uniqueJournal: {
            code: {
                defaultValue: 'SHO'
            },
            label: {
                defaultValue: 'Shopify'
            }
        },
        client: {
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'SHOPIFY'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'SHOPIFY'
            },
            auxiliaryLabelDynamic: {
                defaultValue: '{{ thirdParty.mainName }}'
            },
            auxiliaryAccountDynamic: {
                defaultValue: '{{ thirdParty.mainName | normalize | upper | left(8) }}'
            }
        },
        supplier: {
            visible: false,
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '401'
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'SHOPIFY'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'SHOPIFY'
            },
            auxiliaryLabelDynamic: {
                defaultValue: '{{ thirdParty.mainName }}'
            },
            auxiliaryAccountDynamic: {
                defaultValue: '{{ thirdParty.mainName | normalize | upper | left(8) }}'
            }
        },
        methods: {
            unique: false,
            columns: {
                export: {
                    defaultValue: true
                }
            },
            defaultMethod: {
                name: null,
                export: true,
                journalCode: 'SHO',
                internalTransfer: {
                    account: '581',
                    label: null
                },
                provider: {
                    account: '5171',
                    label: null
                }
            }
        },
        providerFee: {
            account: {
                defaultValue: '62781'
            },
            label: {
                defaultValue: 'Autres frais et commissions sur prestations de services'
            }
        },
        deductibleVat20: {
            visible: false,
            account: {
                defaultValue: '44566'
            },
            label: {
                defaultValue: 'TVA sur autres biens et services'
            }
        },
        reverseCharge: {
            visible: false,
            has: {
                defaultValue: false
            },
            aggregateOption: {
                defaultValue: ReverseChargeAggregateOptionEnum.EACH
            },
            collectedIntracomVat: {
                account: {
                    defaultValue: '4452'
                },
                label: {
                    defaultValue: 'TVA due intracommunautaire'
                }
            },
            deductibleIntracomVat: {
                account: {
                    defaultValue: '445662'
                },
                label: {
                    defaultValue: 'TVA intracommunautaire sur autres biens et services'
                }
            },
            vatRate: {
                defaultValue: 20.00
            }
        },
        description: {
            options: shopifyPaymentDescriptionOptions,
            descriptionTemplate: {
                defaultValue: '{{ transaction.type }} • {{ transaction.subTypeId | default(transaction.transactionId) }} • {{ transaction.creationDate | date("yyyy-MM-dd") }}'
            }
        }
    },

    sellInvoiceProcessorConfiguration: {
        journal: {
            code: {
                defaultValue: 'VTS'
            },
            label: {
                defaultValue: 'Ventes Shopify'
            }
        },
        thirdPartyAccount: {
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'SHOPIFY'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'SHOPIFY'
            },
            auxiliaryLabelDynamic: {
                defaultValue: '{{ buyer.mainName }}'
            },
            auxiliaryAccountDynamic: {
                defaultValue: '{{ buyer.mainName | normalize | upper | left(8) }}'
            }
        },
        generation: {
            useSuggestedProductsAccounts: {
                visible: false,
                defaultValue: false
            },
            option: {
                defaultValue: InvoiceGenerationOptionEnum.ONE_GLOBAL_LINE
            },
            oneGlobalLine: {
                product: {
                    account: {
                        defaultValue: '701000'
                    },
                    label: {
                        defaultValue: 'Produit'
                    }
                }
            }
        },
        useSuggestedVatsAccounts: {
            visible: false,
            defaultValue: false
        },
        collectedVats: {
            columns: {
                regime: {
                    visible: false,
                    canEdit: false
                }
            },
            defaultAccount: {
                countryCode: null,
                regime: null,
                rate: null,
                account: '44571099',
                accountLabel: 'TVA à reclasser'
            },
            accounts: [
                {
                    countryCode: 'FR',
                    regime: null,
                    rate: 5.5,
                    account: '44571055',
                    accountLabel: 'TVA collectée 5,5%'
                },
                {
                    countryCode: 'FR',
                    regime: null,
                    rate: 10,
                    account: '44571010',
                    accountLabel: 'TVA collectée 10%'
                },
                {
                    countryCode: 'FR',
                    regime: null,
                    rate: 20,
                    account: '44571020',
                    accountLabel: 'TVA collectée 20%'
                }
            ]
        },
        amountDifferenceOption: {
            defaultValue: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
        },
        debitAdjustment: {
            account: {
                defaultValue: "658"
            },
            label: {
                defaultValue: "Charges diverses de gestion courante"
            }
        },
        creditAdjustment: {
            account: {
                defaultValue: "758"
            },
            label: {
                defaultValue: "Produits divers de gestion courante"
            }
        },
        description: {
            options: shopifySellInvoiceDescriptionOptions,
            descriptionTemplate: {
                defaultValue: '{{ invoice.type }} • {{ invoice.invoiceFunctionalId | default(invoice.invoiceId) }} • {{ invoice.creationDate | date("yyyy-MM-dd") }}'
            }
        }
    },

    analyticProcessorConfiguration: {
        isEnabled: {
            defaultValue: false
        },
        accountRules: {}
    }
}