import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-load-dex-download-configuration',
  templateUrl: './load-dex-download-configuration.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatSlideToggleModule
  ]
})
export class LoadDexDownloadConfigurationComponent {
  formGroup!: FormGroup;

  constructor(
    private readonly fb: FormBuilder
  ) {
    this.formGroup = fb.group({
      folderId: [null, [Validators.required, Validators.maxLength(8)]],
      generatePartialLettering: [false, Validators.required]
    });
  }
}
