import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CurrentCompanyService } from '../services/current-company.service';
import { CompanyDomain } from '../entities/company-domain.entity';
import { CompanyRight } from '../entities/company-right.entity';

@Injectable()
export class CompanyRightsGuard {

    constructor(
        private router: Router,
        private currentCompanyService: CurrentCompanyService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        // Only execute guard on last sub-route
        if (route.children != null && route.children.length > 0) {
            return of(true);
        }

        const companyRight = route.data['companyRight'] as {domain: CompanyDomain; right: CompanyRight} || null;
        const companyRights = route.data['companyRights'] as {domain: CompanyDomain; right: CompanyRight}[] || null;


        if (companyRight == null && companyRights == null) {
            throw new Error(`Company rights not defined for ${this.getRoute(route)}`);
        } else if (companyRight != null) {
            return this.currentCompanyService
                .currentUserCanAccess$(companyRight.domain, companyRight.right)
                .pipe(
                    map((hasAccess: boolean) => {
                        if (!hasAccess) {
                            this.router.navigate(['/']);
                        }
                        return hasAccess;
                    })
                );
        } else {
            return this.currentCompanyService
                .currentUserCanAccessOneOf$(companyRights)
                .pipe(
                    map((hasAccess: boolean) => {
                        if (!hasAccess) {
                            this.router.navigate(['/']);
                        }
                        return hasAccess;
                    })
                );
        }
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    getRoute(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot.reduce(
            (accumulator, currentValue) => {
                if (currentValue.routeConfig?.path != null && currentValue.routeConfig?.path !== '') {
                    if (accumulator === '') {
                        accumulator += currentValue.routeConfig?.path;
                    } else {
                        accumulator = accumulator + '/' + currentValue.routeConfig?.path;
                    }
                }
                return accumulator;
            },
            ''
        );
    }
}
