import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subscription, combineLatest, concat, connectable, filter, finalize, forkJoin, lastValueFrom, map, of, shareReplay, switchMap, take, takeWhile } from "rxjs";
import { SessionEntity } from "./entities/session.entity";
import { RemoteFileEntity } from "./entities/remote-file.entity";

const MAX_CHUNK_SIZE = 5242880; //~5mb

export type UploadingFileState = 'RAW' | 'UPLOADING' | 'UPLOADED';

export class UploadingFile extends File {
    progressEvent$: Observable<number> | null = null;
    state$: Observable<UploadingFileState> = of('RAW');
    remoteFile$!: Observable<RemoteFileEntity>;
}


export type UploadingFiles = {
    globalProgressEvent$: Observable<number>;
    files: UploadingFile[];
}

@Injectable()
export class UploadFilesService {
    constructor(
        protected readonly http: HttpClient,
    ) { }

    async uploadFiles(
        files: File[]
    ): Promise<UploadingFiles> {
        const reqs: UploadingFile[] = await Promise.all(files.map(this.uploadFile.bind(this)));

        const globalProgressEvent$ = combineLatest([
            concat(reqs)
        ]).pipe(
            map((items: UploadingFile[]) => items.filter(item => item.progressEvent$ != null).map(item => item.progressEvent$) as Observable<number>[]),
            switchMap((reqs: Observable<number>[]) => combineLatest(reqs)),
            map((items: number[]) => items.reduce((a, b) => a + b, 0) / reqs.length), // Do the AVG of the global Process
            shareReplay()
        );

        return {
            files: reqs,
            globalProgressEvent$
        };
    }

    closeUploadReq$(
        sessionId: string
    ): Observable<RemoteFileEntity> {
        const url = `/api/upload-files/sessions/${sessionId}/close`;
        return this.http.post<RemoteFileEntity>(url, {});
    }

    private createUploadSession(
        fileName: string,
        fileSize: number,
        fileHash: string,
        chunksCount: number,
        mimeType?: string
    ): Observable<SessionEntity> {
        const createSessionUrl = `/api/upload-files/sessions`;
            
        // Create upload session
        const data = {
            fileHash,
            fileName,
            fileSize,
            chunksCount,
            mimeType
        };

        return this.http
            .put<{ id: string }>(createSessionUrl, data)
            .pipe(take(1));
    }


    private createChunkUploadRequests(
        sessionId: string,
        chunksCount: number,
        maxChunkSize: number,
        file: File,
    ): Observable<HttpEvent<unknown>>[] {
        const reqs = [];
        for (let index = 0; index < chunksCount; ++index) {

            // Upload chunks
            const data = {
                fileHash: 'hash'
            };

            // Create the formData
            const formData = new FormData();
            // Add the chunkRequest as metadata
            formData.append("chunkRequest", JSON.stringify(data));

            // Prepare the slice and add it in the formdata
            const begin = index * maxChunkSize;
            const end = Math.min(file.size, (index + 1) * maxChunkSize);
            const slice = file.slice(begin, end);

            formData.append("file", slice);

            // Define the request
            const url = `/api/upload-files/sessions/${sessionId}/chunks/${index}`;
            
            const req = new HttpRequest('PUT', url, formData, {reportProgress: true});
            
            const req$ = this.http
                .request(req)
                .pipe(
                    takeWhile((e: HttpEvent<unknown>) => e.type != HttpEventType.Response),
                    filter(e => e.type != HttpEventType.ResponseHeader && e.type != HttpEventType.DownloadProgress),
                );

            reqs.push(req$);
        }
        return reqs;
    }

    /** 
     * Compute the total progress of a collection of HttpEvent, based on the EventType
     * @returns the progress from 0 to 1
     */
    computeProgress(e: HttpEvent<unknown>[]): number {
        let progress = 0;
        e.forEach(res => {
            switch(res.type) {
                case HttpEventType.UploadProgress: {
                    if (res.total) {
                        progress += (res.loaded / res.total);
                    }
                    break;
                }
                case HttpEventType.Response: {
                    progress += 1;
                }
            }
        })
        return progress / e.length;
    }

    async uploadFile(
        file: File,
    ): Promise<UploadingFile> {

        const hash = crypto.randomUUID(); // Generate a random UUID for now to replace the hash
        const maxChunkSize = MAX_CHUNK_SIZE; // ~5 mb
        const chunksCount = Math.ceil(file.size / maxChunkSize);
        const session = await lastValueFrom(this.createUploadSession(file.name, file.size, hash, chunksCount, file.type));
        const chunkRequests = this.createChunkUploadRequests(session.id, chunksCount, maxChunkSize, file);




        const chunkRequests$ = connectable(
            combineLatest(chunkRequests)
            .pipe(
                finalize(() => chunkRequestsSub.unsubscribe())
            ),
        );

        const closeRequest$ = connectable(
            forkJoin([chunkRequests$])
            .pipe(
                switchMap( () => this.closeUploadReq$(session.id)),
                take(1),
                shareReplay(1),
                finalize(() => closeRequestSub.unsubscribe())
            )
        );

        const chunkRequestsSub: Subscription = chunkRequests$.connect();
        const closeRequestSub: Subscription = closeRequest$.connect();


        // 4. Compute the progress of the request
        const progressEvent$ = concat(
            of(0),
            chunkRequests$.pipe(
                map( this.computeProgress.bind(this)),
                shareReplay(1),
            )
        );
        
        const state$ =  concat(
            of<UploadingFileState>('RAW' as UploadingFileState), 
            closeRequest$.pipe(
                map<unknown, UploadingFileState>(() => 'UPLOADING'),
                finalize<UploadingFileState>(() => 'UPLOADED')
            )
        ) as Observable<UploadingFileState>;

        const uploadingFile: UploadingFile = new UploadingFile([file], file.name, {type: file.type, lastModified: file.lastModified});

        uploadingFile.progressEvent$ = progressEvent$;
        uploadingFile.state$ = state$;
        uploadingFile.remoteFile$ = closeRequest$;

        return uploadingFile;
    }
}