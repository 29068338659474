import { InvoiceGenerationOptionEnum } from "~modules/connectors/constants/Invoice-generation.option";
import { AnalyticRuleTypeEnum } from "~modules/connectors/constants/analytic-rule-type.option";
import { InvoiceAmountDifferenceOptionEnum } from "~modules/connectors/constants/invoice-amount-difference.option";
import { JournalOptionEnum } from "~modules/connectors/constants/journal.options";
import { ReverseChargeAggregateOptionEnum } from "~modules/connectors/constants/reverse-charge-aggregate.options";
import { VatRegimeOptionEnum } from "~modules/connectors/constants/vat-regime.option";

export const defaultJournalCodeMaxLength = 5;
export const defaultJournalLabelMaxLength = 35;
export const defaultMainAccountMaxLength = 12;
export const defaultAuxiliaryAccountMaxLength = 20;
export const defaultAccountLabelMaxLength = 70;
export const defaultDynamicConfigMaxLength = 65535;
export const defaultPourcentageMinValue = 0.01;
export const defaultPourcentageMaxValue = 99.99;
export const pourcentageMaxValue = 100;
export const defaultPourcentagePatternValue = new RegExp(/^\d+([.,]\d{0,2})*$/, "i");

export type FieldType<Type> = {
    defaultValue: Type,
    visible?: boolean
};

export type StringFieldType = FieldType<string> & {
    maxLength?: number;
};

export type NumberFieldType = FieldType<number> & {
    minValue?: number;
    maxValue?: number;
    patternValue?: RegExp;
};

export type DateRangeType = {
    visible: boolean,
    fromDate?: FieldType<unknown>,
    toDate?: FieldType<unknown>
}

export type FileType = {
    visible: boolean,
    mimeType: string|null
}

export type JournalSectionFieldsConfiguration = {
    visible?: boolean;
    label: StringFieldType;
    code: StringFieldType;
}

export type AccountSectionFieldsConfiguration = {
    visible?: boolean;
    account: StringFieldType;
    label: StringFieldType;
}

export type ThirdPartyAccountSectionFieldsConfiguration = {
    visible?: boolean;
    useSuggestedAccounts: FieldType<boolean>;
    account: StringFieldType;
    label: StringFieldType;
    isDynamic: FieldType<boolean>;
    auxiliaryAccountStatic: StringFieldType;
    auxiliaryLabelStatic: StringFieldType;
    auxiliaryAccountDynamic: StringFieldType;
    auxiliaryLabelDynamic: StringFieldType;
}

export type RunFieldsConfiguration = {
    runCursor: DateRangeType;
    file: FileType;
}

export type AccountingEntriesFieldsConfiguration = {
    toolLabel: string,
    paymentProcessorConfiguration?: PaymentFieldsConfiguration,
    sellInvoiceProcessorConfiguration?: InvoiceFieldsConfiguration,
    buyInvoiceProcessorConfiguration?: InvoiceFieldsConfiguration,
    analyticProcessorConfiguration?: AnalyticFieldsConfiguration
}

export type FormArrayColumnConfiguration<Type> = {
    canEdit?: boolean;
    defaultValue?: Type;
}

export type PaymentMethodAccountFieldsConfiguration<Type> = {
    account: Type;
    label: string|null
}

export type PaymentMethodFieldsConfiguration = {
    name: string|null;
    export: boolean|null;
    journalCode?: string|null;
    provider?: PaymentMethodAccountFieldsConfiguration<string>;
    internalTransfer?: PaymentMethodAccountFieldsConfiguration<string|null>;
}

export type PaymentMethodsSectionFieldsConfiguration = {
    unique?: boolean;
    canAdd?: boolean;
    canDelete?: boolean;
    paymentMethodOptions?: string[];
    internalTransferVisibleFor?: string[];
    columns?: {
        name?: FormArrayColumnConfiguration<string>,
        export?: FormArrayColumnConfiguration<boolean>,
        journalCode?: FormArrayColumnConfiguration<string>,
        provider?: FormArrayColumnConfiguration<PaymentMethodAccountFieldsConfiguration<string>>,
        internalTransfer?: FormArrayColumnConfiguration<PaymentMethodAccountFieldsConfiguration<string|null>>
    }
    defaultMethod: PaymentMethodFieldsConfiguration;
    methods?: PaymentMethodFieldsConfiguration[];
}

export type VatAccountSectionFieldsConfiguration = {
    countryCode: string|null;
    regime: VatRegimeOptionEnum|null;
    rate: number|null;
    account: string;
    accountLabel: string;
}

export type VatAccountsSectionFieldsConfiguration = {
    canAdd?: boolean;
    canDelete?: boolean;
    columns?: {
        countryCode?: FormArrayColumnConfiguration<string> & { visible?: boolean },
        regime?: FormArrayColumnConfiguration<VatRegimeOptionEnum> & { visible?: boolean },
        rate?: FormArrayColumnConfiguration<number>,
        account?: FormArrayColumnConfiguration<string>,
        accountLabel?: FormArrayColumnConfiguration<string>
    }
    defaultAccount: VatAccountSectionFieldsConfiguration;
    accounts?: VatAccountSectionFieldsConfiguration[];
}

export type AnalyticAccountRuleSectionFieldsConfiguration = {
    type: AnalyticRuleTypeEnum|null;
    account: string|null;
    axis: string|null;
    tag: string;
    value: number;
}

export type AnalyticAccountRulesSectionFieldsConfiguration = {
    visible?: boolean;
    canAdd?: boolean;
    canDelete?: boolean;
    columns?: {
        type?: FormArrayColumnConfiguration<AnalyticRuleTypeEnum>,
        account?: FormArrayColumnConfiguration<string>,
        axis?: FormArrayColumnConfiguration<string>,
        tag?: FormArrayColumnConfiguration<string>,
        value?: FormArrayColumnConfiguration<number>
    }
    accounts?: AnalyticAccountRuleSectionFieldsConfiguration[];
}

export type PaymentFieldsConfiguration = {
    journalOption: FieldType<JournalOptionEnum>;
    uniqueJournal: JournalSectionFieldsConfiguration;
    client: ThirdPartyAccountSectionFieldsConfiguration;
    supplier: ThirdPartyAccountSectionFieldsConfiguration;
    methods: PaymentMethodsSectionFieldsConfiguration;
    providerFee: AccountSectionFieldsConfiguration;
    deductibleVat20: AccountSectionFieldsConfiguration;
    reverseCharge: {
        visible?: boolean;
        has: FieldType<boolean>;
        aggregateOption: FieldType<ReverseChargeAggregateOptionEnum>;
        collectedIntracomVat: AccountSectionFieldsConfiguration;
        deductibleIntracomVat: AccountSectionFieldsConfiguration;
        vatRate: NumberFieldType;
    };
    description: {
        visible?: boolean;
        options: DescriptionOption[];
        defaultOptionValue?: string; // if undefined, then standard option. Otherwise, try to find the good option or return the dynamic option.
        descriptionTemplate: StringFieldType;
    };
}

export type InvoiceFieldsConfiguration = {
    journal: JournalSectionFieldsConfiguration;
    thirdPartyAccount: ThirdPartyAccountSectionFieldsConfiguration;
    generation: {
        visible?: boolean;
        useSuggestedProductsAccounts: FieldType<boolean>;
        option: FieldType<InvoiceGenerationOptionEnum>;
        oneLinePerInvoiceLine?: {
            productVats: VatAccountsSectionFieldsConfiguration;
        },
        oneLinePerVatRate?: {
            productVats: VatAccountsSectionFieldsConfiguration;
        }
        oneGlobalLine?: {
            product: AccountSectionFieldsConfiguration;
        }
    }
    useSuggestedVatsAccounts: FieldType<boolean>;
    collectedVats: VatAccountsSectionFieldsConfiguration;
    amountDifferenceOption: FieldType<InvoiceAmountDifferenceOptionEnum>;
    debitAdjustment: AccountSectionFieldsConfiguration;
    creditAdjustment: AccountSectionFieldsConfiguration;
    description: {
        visible?: boolean;
        options: DescriptionOption[];
        defaultOptionValue?: string; // if undefined, then standard option. Otherwise, try to find the good option or return the dynamic option.
        descriptionTemplate: StringFieldType;
    };
}

export type DescriptionOption = {
    label: string;
    hasDescriptionTemplate: boolean;
    showDescriptionTemplateField?: boolean;
    descriptionTemplateValue?: string;
}

export type AnalyticFieldsConfiguration = {
    isEnabled: FieldType<boolean>;
    accountRules: AnalyticAccountRulesSectionFieldsConfiguration
}