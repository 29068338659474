export type InvoiceAmountDifferenceOptionEnumType = { label: string, value: InvoiceAmountDifferenceOptionEnum};

export enum InvoiceAmountDifferenceOptionEnum {
    ADJUST_HT = 'ADJUST_HT',
    USE_ACCOUNTS = 'USE_ACCOUNTS'
}

export const sellInvoiceAmountDifferenceOptions: InvoiceAmountDifferenceOptionEnumType[] = [
    {
        label: 'Ajuster le montant HT des produits',
        value: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
    },
    {
        label: 'Utiliser des comptes de gestion courante (658 / 758)',
        value: InvoiceAmountDifferenceOptionEnum.USE_ACCOUNTS
    }
]

export const buyInvoiceAmountDifferenceOptions: InvoiceAmountDifferenceOptionEnumType[] = [
    {
        label: 'Ajuster le montant HT des charges',
        value: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
    },
    {
        label: 'Utiliser des comptes de gestion courante (658 / 758)',
        value: InvoiceAmountDifferenceOptionEnum.USE_ACCOUNTS
    }
]