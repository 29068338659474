import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyDetails } from '../entities/company-details.entity';
import { CompanyRight } from '../entities/company-right.entity';
import { CompanyDomain } from '../entities/company-domain.entity';
import { CompanyUserList } from '../entities/company-user-list.entity';
import { CompanyRoleList } from '../entities/company-role-list.entity';
import { UserCreateRequest } from '../entities/user-create-request.entity';
import { UserUpdateRequest } from '../entities/user-update-request.entity';

@Injectable()
export class CompanyService {

  constructor(
      private http: HttpClient,
  ) { }

  getCurrentUserCompanyDetails$(): Observable<CompanyDetails> {
    const url = `/api/company/current`;
    return this.http.get<CompanyDetails>(url);
  }

  getCurrentUserCompanyRights$(): Observable<Record<CompanyDomain, CompanyRight>> {
    const url = `/api/company/current/company-rights`;
    return this.http.get<Record<CompanyDomain, CompanyRight>>(url);
  }

  getCompanyUsers$(companyId: number): Observable<CompanyUserList[]> {
    const url = `/api/company/${companyId}/users`;
    return this.http.get<CompanyUserList[]>(url);
  }

  getCompanyRoles$(companyId: number): Observable<CompanyRoleList[]> {
    const url = `/api/company/${companyId}/company-roles`;
    return this.http.get<CompanyRoleList[]>(url);
  }

  createUser$(companyId: number, createUpdateRequest: UserCreateRequest): Observable<void> {
    const url = `/api/company/${companyId}/users`;
    return this.http.post<void>(url, createUpdateRequest);
  }

  updateUser$(companyId: number, userId: number, createUpdateRequest: UserUpdateRequest): Observable<void> {
    const url = `/api/company/${companyId}/users/${userId}`;
    return this.http.post<void>(url, createUpdateRequest);
  }

  deleteUser$(companyId: number, userId: number): Observable<void> {
    const url = `/api/company/${companyId}/users/${userId}`;
    return this.http.delete<void>(url);
  }
}
