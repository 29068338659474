<form [formGroup]="formGroup">

  <mat-slide-toggle formControlName="isEnabled" *ngIf="fieldsConfig?.isEnabled?.visible !== false">
    <div class="text-and-icon-container">
      <span>Activer</span>
    </div>
  </mat-slide-toggle>

  <ng-container *ngIf="formGroup.value.isEnabled === true">
    <br />
    <fieldset *ngIf="fieldsConfig?.accountRules?.visible !== false">
      <legend>Règles analytique sur les comptes</legend>
      <app-analytic-account-rule-configuration
        [fieldsConfiguration]="fieldsConfig?.accountRules"
        [accountRules]="analyticConfig?.accountRules"
        [toolLabel]="toolLabel"
        addLabel="Ajouter une règle analytique"
        #accountRules
      ></app-analytic-account-rule-configuration>
    </fieldset>
  </ng-container>
</form>