<form [formGroup]="formGroup">

  <h4><mat-icon>login</mat-icon>&nbsp;Connexion</h4>

  <mat-form-field class="full-width">
      <mat-label>Choix de la connexion</mat-label>
      <mat-select formControlName="authenticationId" required>
          <mat-option 
              *ngFor="let connection of connectionsOptions$ | async"
              [value]="connection.id">
              {{connection.displayName}}
          </mat-option>
      </mat-select>
  </mat-form-field>

</form>