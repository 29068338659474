import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { CurrentCompanyService } from '../services/current-company.service';
import { CompanyDomain } from '../entities/company-domain.entity';
import { CompanyRight } from '../entities/company-right.entity';

@Directive({
    selector: '[appCurrentCompanyRight]'
})
export class CurrentCompanyRightDirective {

    constructor(
        private readonly templateRef: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
        private readonly currentCompanyService: CurrentCompanyService,
    ) {}

    @Input()
    set appCurrentCompanyRight({domain, right}: {domain: CompanyDomain, right: CompanyRight}) {

        this.currentCompanyService
            .currentUserCanAccess$(domain, right)
            .pipe(
                first()
            ).subscribe(res => {
                if (res === true) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }
}
