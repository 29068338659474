import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TraFormatService {

  constructor(
      private http: HttpClient,
  ) { }

  extractAccountingFolder$(
    remoteFileId: string
  ): Observable<Blob> {
    const url = `/api/tools/tra-format-extraction`;

    return this.http.post(url, {remoteFileId}, {responseType: 'blob'});
  }
}
