import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SavExtractRequest } from '../entities/sav-extract-request.entity';

@Injectable()
export class SavFormatService {

  constructor(
      private http: HttpClient,
  ) { }

  extractAccountingAssets(
    remoteFileId: string, 
    outputFormat: string
  ): Observable<Blob> {
    
    const url = `/api/tools/accounting-assets/extract`;

    const data: SavExtractRequest = {
      remoteFileId,
      outputFormat,
    };

    return this.http.post(url, data, {responseType: 'blob'});
  }
}
