import { NgModule } from "@angular/core";
import { AllConnectorsReferenceService } from "./services/all-connectors-reference.service";
import { ArtifactFilesService } from "./services/artifact-files.service";
import { CompanyModule } from '~modules/company/company.module';
import { ConnectorRunsService } from "./services/connector-runs.service";
import { ExtractConnectorsService } from "./services/extract-connectors.service";
import { GlobalConnectorsService } from "./services/global-connectors.service";
import { LoadConnectorsService } from "./services/load-connectors.service";
import { TransformConnectorsService } from "./services/transform-connectors.service";

@NgModule({
    imports: [CompanyModule],
    providers: [
        ExtractConnectorsService,
        LoadConnectorsService,
        TransformConnectorsService,
        GlobalConnectorsService,
        ConnectorRunsService,
        AllConnectorsReferenceService,
        ArtifactFilesService,
    ],
    declarations: [
    ]
})
export class ConnectorsModule {}