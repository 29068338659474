import { RunFieldsConfiguration } from "../../field-type";


export const apiRunFieldsConfiguration: RunFieldsConfiguration = {

    runCursor: {
        visible: true
    },
    file: {
        visible: false,
        mimeType: null
    }

}