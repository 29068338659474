import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OfferService } from './services/offer.service';

@NgModule({
  imports: [
    HttpClientModule
  ]
})
export class OfferModule {
  static forRoot(): ModuleWithProviders<OfferModule> {
    return {
      ngModule: OfferModule,
      providers: [
        OfferService
      ],
    };
   }
}
