import { Injectable } from "@angular/core";
import { ExtractConnectorType } from "../models/extract-connector.type";
import { LoadConnectorType } from "../models/load-connector.type";
import { ExtractConnectorsService } from "./extract-connectors.service";
import { TransformConnectorsService } from "./transform-connectors.service";
import { LoadConnectorsService } from "./load-connectors.service";
import { TransformConnectorType } from "../models/transform-connector.type";
import { Observable, combineLatest, map, take } from 'rxjs';

export type AnyConnectorType = ExtractConnectorType | TransformConnectorType | LoadConnectorType;

@Injectable()
export class AllConnectorsReferenceService {

    constructor(
        private readonly extractConnectorService: ExtractConnectorsService,
        private readonly transformConnectorService: TransformConnectorsService,
        private readonly loadConnectorService: LoadConnectorsService,
    ){}

    getAllAsMap()
        : Map<string, AnyConnectorType> {
        const res = new Map<string, AnyConnectorType>();
        [
            ...this.extractConnectorService.getList(),
            ...this.transformConnectorService.getList(),
            ...this.loadConnectorService.getList(),
        ].forEach(
            item => res.set(item.type, item)
        );

        return res;
    }

    getAvailableExtractAndLoadTypesAsArray(): Observable<string[]> {
        return combineLatest([
            this.extractConnectorService.getAvailableOnes$(),
            this.loadConnectorService.getAvailableOnes$()
        ]).pipe(
            take(1),
            map(([extractConnectors, loadConnectors]) => {
                const mergedConnectors = [...extractConnectors, ...loadConnectors];
                return mergedConnectors.map(value => value.type.toString());
            })
        );
    }
}