export type AnalyticRuleTypeEnumType = { label: string, tooltip: string, value: AnalyticRuleTypeEnum};

export enum AnalyticRuleTypeEnum {
    ALL = 'ALL',
    STRICT = 'STRICT',
    START_WITH = 'START_WITH'
}

export const analyticRuleTypes: AnalyticRuleTypeEnumType[] = [
    {
        label: 'Tous',
        tooltip: 'Tous les comptes',
        value: AnalyticRuleTypeEnum.ALL
    },
    {
        label: 'Exact',
        tooltip: 'Compte ayant la même valeur',
        value: AnalyticRuleTypeEnum.STRICT
    },
    {
        label: 'Racine',
        tooltip: 'Compte commençant par',
        value: AnalyticRuleTypeEnum.START_WITH
    }
]