import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FecVerifierService } from './fecverifier/services/fecverifier.service';
import { SavFormatService } from './savformat/services/sav-format.service';
import { TraFormatService } from './traformat/services/tra-format.service';
import { CoalaFormatService } from './coalaformat/services/coala-format.service';
import { UploadFilesModule } from '~modules/upload-files/upload-files.module';
import { AsciiFormatService } from './asciiformat/services/ascii-format.service';
import { ExtractAccountingEntriesService } from './extract-accounting-entries/services/extract-accounting-entries.service';

@NgModule({
  imports: [
    HttpClientModule,
    UploadFilesModule,
  ]
})
export class ToolsModule {
  static forRoot(): ModuleWithProviders<ToolsModule> {
    return {

      ngModule: ToolsModule,
      providers: [
        FecVerifierService,
        ExtractAccountingEntriesService,
        SavFormatService,
        TraFormatService,
        CoalaFormatService,
        AsciiFormatService
      ],
    };
   }
}
