import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MigrationAccountingFolderJournalMappingUpdateRequest } from '../entities/mapping/folder/migration-accounting-folder-journal-mapping-update-request.entity';

@Injectable()
export class MigrationAccountingFolderJournalMappingService {

  constructor(
      private http: HttpClient,
  ) { }

  updateJournalMapping$(folderId: number, journalMappingId: number, request: MigrationAccountingFolderJournalMappingUpdateRequest): Observable<void> {
    const url = `/api/migration/folder/${folderId}/journalMappings/${journalMappingId}`;

    return this.http.post<void>(url, request);
  }
}
