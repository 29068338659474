import { EUCountryVAT } from '~modules/connectors/constants/country-code-constants';

export const allEUVATRates: number[] = [
  0, 2.1, 3, 4, 5, 5.5, 6, 7, 8, 9, 9.5, 10, 12, 13, 13.5, 14, 16, 17, 18, 19,
  20, 21, 22, 23, 24, 25, 27
];

type EuVatRates = {
  code: string,
  rates: number[]
}

export const euVatRates: EuVatRates[] = [
  { code: EUCountryVAT.ALL, rates: allEUVATRates },
  { code: EUCountryVAT.AT, rates: [20, 19, 13, 10, 5, 0] }, // Austria
  { code: EUCountryVAT.BE, rates: [21, 12, 6, 0] }, // Belgium
  { code: EUCountryVAT.BG, rates: [20, 9, 0] }, // Bulgaria
  { code: EUCountryVAT.HR, rates: [25, 13, 5, 0] }, // Croatia
  { code: EUCountryVAT.CY, rates: [19, 9, 5, 0] }, // Cyprus
  { code: EUCountryVAT.CZ, rates: [21, 15, 10, 0] }, // Czech Republic
  { code: EUCountryVAT.DK, rates: [25, 0] }, // Denmark
  { code: EUCountryVAT.EE, rates: [20, 9, 5, 0] }, // Estonia
  { code: EUCountryVAT.FI, rates: [24, 14, 10, 0] }, // Finland
  { code: EUCountryVAT.FR, rates: [20, 10, 5.5, 2.1, 0] }, // France
  { code: EUCountryVAT.DE, rates: [19, 7, 0] }, // Germany
  { code: EUCountryVAT.GR, rates: [24, 13, 6, 0] }, // Greece
  { code: EUCountryVAT.HU, rates: [27, 18, 5, 0] }, // Hungary
  { code: EUCountryVAT.IE, rates: [23, 13.5, 9, 0] }, // Ireland
  { code: EUCountryVAT.IT, rates: [22, 10, 5, 4, 0] }, // Italy
  { code: EUCountryVAT.LV, rates: [21, 12, 5, 0] }, // Latvia
  { code: EUCountryVAT.LT, rates: [21, 9, 5, 0] }, // Lithuania
  { code: EUCountryVAT.LU, rates: [17, 14, 8, 3] }, // Luxembourg
  { code: EUCountryVAT.MT, rates: [18, 7, 5, 0] }, // Malta
  { code: EUCountryVAT.NL, rates: [21, 9, 0] }, // Netherlands
  { code: EUCountryVAT.PL, rates: [23, 8, 5, 0] }, // Poland
  { code: EUCountryVAT.PT, rates: [23, 22, 16, 13, 12, 9, 6, 5, 4] }, // Portugal with Madeira and Azores (mainland : 23, 13, 6 | Madeira : 22, 12, 5 | Azores: 16, 9, 4)
  { code: EUCountryVAT.RO, rates: [19, 9, 5, 0] }, // Romania
  { code: EUCountryVAT.SK, rates: [20, 10, 5, 0] }, // Slovakia
  { code: EUCountryVAT.SI, rates: [22, 9.5, 0] }, // Slovenia
  { code: EUCountryVAT.ES, rates: [21, 10, 5, 4, 0] }, // Spain
  { code: EUCountryVAT.SE, rates: [25, 12, 6, 0] } // Sweden
];