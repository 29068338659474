import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { UploadFilesService } from "./upload-files.service";

@NgModule({
    imports: [
        HttpClientModule,
    ],
    providers: [
        UploadFilesService,
    ],
})
export class UploadFilesModule {}
