export type ReverseChargeAggregateOptionType = { label: string, value: string};

export enum ReverseChargeAggregateOptionEnum {
    EACH = 'EACH',
    GROUPED = 'GROUPED'
}

export const reverseChargeAggregateOptions: ReverseChargeAggregateOptionType[] = [
    {
        label: 'Sur chaque transaction éligible',
        value: ReverseChargeAggregateOptionEnum.EACH
    },
    {
        label: 'Ecriture de regroupement (une seule écriture pour l\'export)',
        value: ReverseChargeAggregateOptionEnum.GROUPED
    },
]