import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { AccountingEntryNumberModeEnum } from '~modules/connectors/constants/accounting-entry-number-mode-constants';

@Component({
  selector: 'app-load-inqom-zip-fec-download-configuration',
  templateUrl: './load-inqom-zip-fec-download-configuration.component.html',
  styleUrls: ['./load-inqom-zip-fec-download-comfiguration.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatSlideToggleModule
  ]
})
export class LoadInqomZipFecDownloadConfigurationComponent {
  formGroup!: FormGroup;

  constructor(
    private readonly fb: FormBuilder
  ) {
    this.formGroup = fb.group({
      generatePartialLettering: [false, Validators.required],
      accountingEntryNumberMode: [AccountingEntryNumberModeEnum.PER_JOURNAL]
    });
  }

  onToggleChange(event: MatSlideToggleChange): void {
    if (event.checked === true) {
      this.formGroup.get('accountingEntryNumberMode')?.setValue(AccountingEntryNumberModeEnum.PER_JOURNAL);
    } else {
      this.formGroup.get('accountingEntryNumberMode')?.setValue(AccountingEntryNumberModeEnum.NONE);
    }
  }
}
