import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';

import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from '~modules/auth/auth.module';
import { ToolsModule } from '~modules/tools/tools.module';

import { AppComponent } from './app.component';
import { MigrationModule } from '~modules/migration/migration.module';
import { ConfirmationDialogModule } from './components/confirmation-dialog/confirmation-dialog.module';
import { ThirdPartsModule } from '~modules/thirdparts/third-parts.module';
import { ErrorsManagementModule } from './modules/errors-management/errors-management.module';
import { HttpErrorsInterceptor } from '~modules/errors-management/interceptors/http-errors.interceptor';
import { JwtInterceptor } from '~modules/auth/helpers/jwt.interceptor';
import { CompanyModule } from '~modules/company/company.module';
import { OfferModule } from '~modules/offer/offer.module';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ErrorsManagementModule,
    AppRoutingModule,

    // shared modules
    ConfirmationDialogModule,
    MatSnackBarModule, 

    // sub modules
    AuthModule.forRoot(),
    ToolsModule.forRoot(),
    MigrationModule.forRoot(),
    ThirdPartsModule.forRoot(),
    CompanyModule.forRoot(),
    OfferModule.forRoot()

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
