import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, take } from "rxjs";
import { userDownloadItem } from "~modules/downloadable-items/create-dl-item.util";

@Injectable()
export class ArtifactFilesService {

    constructor(
        private readonly http: HttpClient
    ) {}

    public static getPath() {
        return `/api/artifact-files`;
    }

    getBlobById(id: string): Observable<HttpResponse<Blob>> {
        const url = `${ArtifactFilesService.getPath()}/${id}`;
        return this.http.get(url, {responseType: 'blob', observe: 'response'});
    }

    userDownloadById(
        id: string,
        defaultFileName: string,
    ): void {
        this.getBlobById(id)
            .pipe(
                take(1)
            ).subscribe({
                next: (blobResponse: HttpResponse<Blob>) => {
                    const contentDisposition = blobResponse.headers.get('content-disposition');
                    const fileName = contentDisposition?.split(';')[1].split('filename')[1].split('=')[1].trim();
                    userDownloadItem({blob: blobResponse.body as Blob,  fileName: fileName || defaultFileName});
                }
            });
    }
}