import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ThirdPartsAuthenticationList } from '~modules/thirdparts/common/entities/third-parts-authentication-list.entity';
import { ThirdPartsQontoAuthenticationManualBulkCreateRequest } from '../entities/third-parts-qonto-authentication-manual-bulk-create-request.entity - Copie';
import { ThirdPartsQontoAuthenticationManualUpdateRequest } from '../entities/third-parts-qonto-authentication-manual-update-request.entity';
import { ThirdPartsQontoAuthenticationDetails } from '../entities/third-parts-qonto-authentication-details.entity';

@Injectable()
export class ThirdPartsQontoAuthenticationService {

  constructor(private readonly http: HttpClient) { }

  public list$(): Observable<ThirdPartsAuthenticationList[]> {
    const path = `/api/third-parts/qonto/authentication/list`;
    return this.http.get<ThirdPartsAuthenticationList[]>(path);
  }

  public getOne$(id: number): Observable<ThirdPartsQontoAuthenticationDetails> {
    const path = `/api/third-parts/qonto/authentication/${id}`;
    return this.http.get<ThirdPartsQontoAuthenticationDetails>(path);
  }

  public createMany$(request: ThirdPartsQontoAuthenticationManualBulkCreateRequest) {
    const url = `/api/third-parts/qonto/authentication`;

    return this.http.post<void>(url, request);
  }

  public update$(request: ThirdPartsQontoAuthenticationManualUpdateRequest) {
    const url = `/api/third-parts/qonto/authentication`;

    return this.http.put<void>(url, request);
  }
}
