import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConnectorEntity } from "../entities/connector.entity";
import { CreateUpdateGlobalConnectorRequest } from "../dtos/create-update-global-connector.request";
import { AccountingFoldersService } from "~modules/accounting-folders/services/accounting-folders.service";
import { Observable } from "rxjs";
import { RunIterationConnectorListItemResponse } from "../dtos/run-iteration-connector-list-item.response";
import { RangeDate } from "src/app/components/date-range-input/date-range-input.component";
import { RemoteFileEntity } from "~modules/upload-files/entities/remote-file.entity";

@Injectable()
export class GlobalConnectorsService {

    constructor(
        private readonly http: HttpClient,
    ) {}

    
    static getPath(folderId: string) {
        return `${AccountingFoldersService.getPath()}/${folderId}/${GlobalConnectorsService.getFragmentPath()}`;
    }

    static getFragmentPath(): string {
        return `connectors`;
    }

    createIntoFolder(
        dto: CreateUpdateGlobalConnectorRequest,
        folderId: string
    ): Observable<ConnectorEntity> {
        const url = GlobalConnectorsService.getPath(folderId);
        return this.http.put<ConnectorEntity>(url, dto);
    }

    editIntoFolder(
        folderId: string,
        dto: CreateUpdateGlobalConnectorRequest,
        connectorId: string
    ): Observable<ConnectorEntity> {
        const url= `${GlobalConnectorsService.getPath(folderId)}/${connectorId}`;
        return this.http.put<ConnectorEntity>(url, dto);
    }


    getAllByFolderId(
        folderId: string
    ): Observable<ConnectorEntity[]> {
        const url = GlobalConnectorsService.getPath(folderId);
        return this.http.get<ConnectorEntity[]>(url);
    }

    getAll() : Observable<ConnectorEntity[]> {
        const url = `${AccountingFoldersService.getPath()}/${GlobalConnectorsService.getFragmentPath()}/list`;
        return this.http.get<ConnectorEntity[]>(url);
    }

    getGlobalConnectorConfigurationByIdAndFolderIdForEdit(
        folderId: string,
        connectorId: string
    ): Observable<CreateUpdateGlobalConnectorRequest> {
        const url= `${GlobalConnectorsService.getPath(folderId)}/${connectorId}/edit-config`;
        return this.http.get<CreateUpdateGlobalConnectorRequest>(url);
    }

    getGlobalConnectorConfigurationByIdAndFolderIdForCopy(
        folderId: string,
        connectorId: string
    ): Observable<CreateUpdateGlobalConnectorRequest> {
        const url= `${GlobalConnectorsService.getPath(folderId)}/${connectorId}/copy-config`;
        return this.http.get<CreateUpdateGlobalConnectorRequest>(url);
    }

    getOneByFolderIdAndId(
        folderId: string,
        connectorId: string
    ): Observable<ConnectorEntity> {
        const url = `${GlobalConnectorsService.getPath(folderId)}/${connectorId}`;
        return this.http.get<ConnectorEntity>(url);
    }

    run(
        folderId: string,
        connectorId: string,
        rangeDate: RangeDate|null,
        remoteFile: RemoteFileEntity|null
    ): Observable<RunIterationConnectorListItemResponse> {
        const url = `${GlobalConnectorsService.getPath(folderId)}/${connectorId}/runs`;
        let runCursor = null;
        if (rangeDate != null) {
            runCursor = {
                fromDate: rangeDate.from,
                toDate: rangeDate.to,
            }
        }
        return this.http.post<RunIterationConnectorListItemResponse>(url, {
            runCursor: runCursor,
            fileUploadRemoteFileId: remoteFile?.id
        });
    }

    softDeleteById(
        folderId: string,
        connectorId: string
    ): Observable<ConnectorEntity> {
        const url = `${GlobalConnectorsService.getPath(folderId)}/${connectorId}`;
        return this.http.delete<ConnectorEntity>(url);
    }
}