import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MigrationAccountingFolderDetailsFiscalYearEditRequest } from '../entities/details/migration-accounting-folder-details-fiscal-year-edit-request.entity';

@Injectable()
export class MigrationFiscalYearService {

  constructor(
      private http: HttpClient,
  ) { }

  downloadFile$(folderId: number, fiscalYearId: number): Observable<Blob> {
    const url = `/api/migration/folder/${folderId}/fiscalYears/${fiscalYearId}/fec`;

    return this.http.get(url, {responseType: 'blob'});
  }

  updateFiscalYear$(folderId: number, fiscalYearId: number, request: MigrationAccountingFolderDetailsFiscalYearEditRequest): Observable<void> {
    const url = `/api/migration/folder/${folderId}/fiscalYears/${fiscalYearId}`;

    return this.http.post<void>(url, request);
  }
}
