import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ThirdPartsType } from '../entities/third-parts-type.constant';
import { ThirdPartsAuthenticationList } from '../entities/third-parts-authentication-list.entity';

@Injectable()
export class CommonThirdPartsAuthenticationService {

  constructor(private readonly http: HttpClient) { }

  public listByAuthenticationType$(authenticationType: ThirdPartsType): Observable<ThirdPartsAuthenticationList[]> {
    const path = `/api/third-parts/authentication/list`;
    return this.http.get<ThirdPartsAuthenticationList[]>(path, {
      params: {
        authenticationType
      }
    });
  }
}
