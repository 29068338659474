import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Observable } from 'rxjs';
import { ThirdPartsAuthenticationList } from '~modules/thirdparts/common/entities/third-parts-authentication-list.entity';
import { ThirdPartsAxonautAuthenticationService } from '../../services/third-parts-axonaut-authentication.service';
import { AxonautFilterDateOptionEnum, axonautFilterDateOptions } from '../../constants/axonaut-filter-date.option';
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
  selector: 'app-axonaut-accounting-entries-api-exporter-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule
  ],
  templateUrl: './axonaut-ae-api-exporter-configuration.component.html',
  styleUrls: ['./axonaut-ae-api-exporter-configuration.component.scss']
})
export class AxonautAccountingEntriesApiExporterConfigurationComponent {
  formGroup: FormGroup;

  connectionsOptions$?: Observable<ThirdPartsAuthenticationList[]>;

  axonautFilterDateOptions = axonautFilterDateOptions;

  constructor(
    readonly fb: FormBuilder,
    protected readonly authenticationService: ThirdPartsAxonautAuthenticationService,

  ) {
    this.formGroup = fb.group({
      authenticationId: [null, Validators.required],
      dateFilter: [AxonautFilterDateOptionEnum.CREATION, Validators.required]
    });

    this.connectionsOptions$ = this.authenticationService.list$();
  }

  compareFunction(o1: AxonautFilterDateOptionEnum, o2: AxonautFilterDateOptionEnum) {
    return (o1 == null && o2 == null) || (o1 != null && o2 != null && o1 === o2);
  }
}