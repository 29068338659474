import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ThirdPartsAuthenticationList } from '~modules/thirdparts/common/entities/third-parts-authentication-list.entity';

@Injectable()
export class ThirdPartsNextMotionAuthenticationService {

  constructor(private readonly http: HttpClient) { }

  public list$(): Observable<ThirdPartsAuthenticationList[]> {
    const path = `/api/third-parts/next-motion/authentication/list`;
    return this.http.get<ThirdPartsAuthenticationList[]>(path);
  }
}
