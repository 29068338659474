import { NgModule } from '@angular/core';
import {ConfirmationDialogComponent} from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [MatIconModule, MatDialogModule, MatButtonModule],
  declarations: [ConfirmationDialogComponent],
  exports: [
    ConfirmationDialogComponent,
  ],
  providers: []
})
export class ConfirmationDialogModule { }
