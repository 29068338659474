<form [formGroup]="formGroup">
  <div class="row" *ngIf="fieldsConfig?.useSuggestedAccounts?.visible !== false">
    <mat-slide-toggle formControlName="useSuggestedAccounts">
      <div class="text-and-icon-container">
        <span>Reprendre la configuration comptable {{ toolLabel }} des {{thirdPartyTypeLabel}}s</span>&nbsp;
      </div>
    </mat-slide-toggle>
  </div>
  <div class="row danger-info" *ngIf="formGroup.value.useSuggestedAccounts === true">
    <small>Si les comptes comptables ne sont pas configurés dans {{ toolLabel }}, les comptes suivants seront utilisés par défaut.</small>
  </div>
  <div class="row">
    <mat-slide-toggle formControlName="hasDynamic" class="full-width" *ngIf="fieldsConfig?.isDynamic?.visible !== false">
      Auxiliaire avancé
    </mat-slide-toggle>
  </div>
  <div class="row">
    <mat-form-field class="third-width"  *ngIf="fieldsConfig?.account?.visible !== false">
      <mat-label>Compte {{thirdPartyTypeLabel}} racine</mat-label>
      <input matInput formControlName="account" [placeholder]="fieldsConfig?.account?.defaultValue || ''">
    </mat-form-field>
    <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.account?.visible !== false">
      <mat-label>Libellé compte {{thirdPartyTypeLabel}} racine</mat-label>
      <input matInput formControlName="accountLabel" [placeholder]="fieldsConfig?.label?.defaultValue || ''">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="full-width"
      *ngIf="formGroup.value.hasDynamic === false && fieldsConfig?.auxiliaryAccountStatic?.visible !== false">
      <mat-label>Compte {{thirdPartyTypeLabel}} auxiliaire</mat-label>
      <input matInput formControlName="auxiliaryAccountStatic" [placeholder]="fieldsConfig?.auxiliaryAccountStatic?.defaultValue || ''">
    </mat-form-field>
    <mat-form-field class="full-width"
      *ngIf="formGroup.value.hasDynamic === true && fieldsConfig?.auxiliaryAccountDynamic?.visible !== false">
      <mat-label>Compte {{thirdPartyTypeLabel}} auxiliaire</mat-label>
      <textarea matInput
      (keypress)="avoidLineBreak($event)"
      formControlName="auxiliaryAccountDynamic"
      [placeholder]="fieldsConfig?.auxiliaryAccountDynamic?.defaultValue || ''"
      cdkTextareaAutosize
      #accountAutosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5">
      </textarea>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="full-width" *ngIf="formGroup.value.hasDynamic === false && fieldsConfig?.auxiliaryLabelStatic?.visible !== false">
      <mat-label>Libellé compte {{thirdPartyTypeLabel}} auxiliaire</mat-label>
      <input matInput formControlName="auxiliaryAccountLabelStatic" [placeholder]="fieldsConfig?.auxiliaryLabelStatic?.defaultValue || ''">
    </mat-form-field>
    <mat-form-field class="full-width" *ngIf="formGroup.value.hasDynamic === true && fieldsConfig?.auxiliaryLabelDynamic?.visible !== false">
      <mat-label>Libellé compte {{thirdPartyTypeLabel}} auxiliaire</mat-label>
      <textarea matInput
      (keypress)="avoidLineBreak($event)"
      formControlName="auxiliaryAccountLabelDynamic"
      [placeholder]="fieldsConfig?.auxiliaryLabelDynamic?.defaultValue || ''"
      cdkTextareaAutosize
      #accountLabelAutosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5">
      </textarea>
    </mat-form-field>
  </div>
</form>