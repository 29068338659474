export type AccountingEntryNumberModeEnumType = { label: string, value: AccountingEntryNumberModeEnum};

export enum AccountingEntryNumberModeEnum {
    NONE = 'NONE',
    PER_JOURNAL = 'PER_JOURNAL',
    GLOBAL = 'GLOBAL'
}

export const accountryEntryNumberModes: AccountingEntryNumberModeEnumType[] = [
    {
        label: 'Pas de numéro d\'écriture comptable',
        value: AccountingEntryNumberModeEnum.NONE
    },
    {
        label: 'Attribuer un numéro unique aux écritures comptables par journal',
        value: AccountingEntryNumberModeEnum.PER_JOURNAL
    },
    {
        label: 'Attribuer un numéro unique aux écritures comptables tous journaux confondus',
        value: AccountingEntryNumberModeEnum.GLOBAL
    }
]