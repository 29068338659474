import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { environment } from 'src//environments/environment';


@Component({
  selector: 'app-anon-layout',
  templateUrl: './anon-layout.component.html',
  styleUrls: ['./anon-layout.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    LayoutModule,
    RouterModule,
    CommonModule,
  ]
})
export class AnonLayoutComponent {
  currentApplicationVersion = environment.appVersion;
}
