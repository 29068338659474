import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AccountingEntriesFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { AccountingEntriesProcessorConfiguration } from '~modules/tools/extract-accounting-entries/entities/accounting-entries-processor-configuration.entity';
import { InvoiceProcessorConfiguration } from '~modules/tools/extract-accounting-entries/entities/invoice-processor-configuration.entity';
import { PaymentProcessorConfiguration } from '~modules/tools/extract-accounting-entries/entities/payment-processor-configuration.entity';
import { InvoiceProcessorConfigurationComponent } from '../invoice-processor-configuration/invoice-processor-configuration.component';
import { PaymentProcessorConfigurationComponent } from '../payment-processor-configuration/payment-processor-configuration.component';
import { AnalyticProcessorConfigurationComponent } from '../analytic-processor-configuration/analytic-processor-configuration.component';
import { AnalyticProcessorConfiguration } from '../../entities/analytic-processor-configuration.entity';

export type OptionTypes = 'PAYMENT'|'SELL-INVOICE'|'BUY-INVOICE'|'PAYMENT_SELL-INVOICE'|'PAYMENT_BUY-INVOICE'|'SELL-INVOICE_BUY-INVOICE'|'PAYMENT_SELL-INVOICE_BUY-INVOICE';
export type OptionTypesConfiguration = { label: string, hasPaymentConfiguration: boolean, hasSellInvoiceConfiguration: boolean, hasBuyInvoiceConfiguration: boolean };
export const configurationOptionDefinitions: Record<OptionTypes, OptionTypesConfiguration> = {
  "PAYMENT": {
    label: 'ENCAISSEMENT',
    hasPaymentConfiguration: true,
    hasSellInvoiceConfiguration: false,
    hasBuyInvoiceConfiguration: false
  },
  "SELL-INVOICE": {
    label: 'VENTE',
    hasPaymentConfiguration: false,
    hasSellInvoiceConfiguration: true,
    hasBuyInvoiceConfiguration: false
  },
  "BUY-INVOICE": {
    label: 'ACHAT',
    hasPaymentConfiguration: false,
    hasSellInvoiceConfiguration: false,
    hasBuyInvoiceConfiguration: true
  },
  "PAYMENT_SELL-INVOICE": {
    label: 'ENCAISSEMENT + VENTE',
    hasPaymentConfiguration: true,
    hasSellInvoiceConfiguration: true,
    hasBuyInvoiceConfiguration: false
  },
  "PAYMENT_BUY-INVOICE": {
    label: 'ENCAISSEMENT + ACHAT',
    hasPaymentConfiguration: true,
    hasSellInvoiceConfiguration: false,
    hasBuyInvoiceConfiguration: true
  },
  "SELL-INVOICE_BUY-INVOICE": {
    label: 'VENTE + ACHAT',
    hasPaymentConfiguration: false,
    hasSellInvoiceConfiguration: true,
    hasBuyInvoiceConfiguration: true
  },
  "PAYMENT_SELL-INVOICE_BUY-INVOICE": {
    label: 'ENCAISSEMENT + VENTE + ACHAT',
    hasPaymentConfiguration: true,
    hasSellInvoiceConfiguration: true,
    hasBuyInvoiceConfiguration: true
  }
};

@Component({
  selector: 'app-accounting-entries-processor-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PaymentProcessorConfigurationComponent,
    InvoiceProcessorConfigurationComponent,
    AnalyticProcessorConfigurationComponent,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule
  ],
  templateUrl: './accounting-entries-processor-configuration.component.html',
  styleUrls: ['./accounting-entries-processor-configuration.component.scss']
})
export class AccountingEntriesProcessorConfigurationComponent {

  formGroup!: FormGroup;

  @ViewChild(PaymentProcessorConfigurationComponent)
  set paymentConfigurationComponent(value: PaymentProcessorConfigurationComponent) {
    this.formGroup.setControl('paymentProcessorConfiguration', value?.formGroup);
  }
  @ViewChild('sellInvoiceProcessorConfiguration')
  set sellInvoiceConfigurationComponent(value: InvoiceProcessorConfigurationComponent) {
    this.formGroup.setControl('sellInvoiceProcessorConfiguration', value?.formGroup);
  }
  @ViewChild('buyInvoiceProcessorConfiguration')
  set buyInvoiceConfigurationComponent(value: InvoiceProcessorConfigurationComponent) {
    this.formGroup.setControl('buyInvoiceProcessorConfiguration', value?.formGroup);
  }
  @ViewChild(AnalyticProcessorConfigurationComponent)
  set analyticConfigurationComponent(value: AnalyticProcessorConfigurationComponent) {
    this.formGroup.setControl('analyticProcessorConfiguration', value?.formGroup);
  }
  configurationOptions: OptionTypesConfiguration[] = [];
  fieldsConfig: AccountingEntriesFieldsConfiguration | undefined;
  paymentConfiguration: PaymentProcessorConfiguration | undefined;
  sellInvoiceConfiguration: InvoiceProcessorConfiguration | undefined;
  buyInvoiceConfiguration: InvoiceProcessorConfiguration | undefined;
  analyticConfiguration: AnalyticProcessorConfiguration | undefined;

  @Input()
  set existingConfiguration(value: AccountingEntriesProcessorConfiguration | undefined) {
    this.paymentConfiguration = value?.paymentProcessorConfiguration;
    this.sellInvoiceConfiguration = value?.sellInvoiceProcessorConfiguration;
    this.buyInvoiceConfiguration = value?.buyInvoiceProcessorConfiguration;
    this.analyticConfiguration = value?.analyticProcessorConfiguration;
  }

  @Input()
  set fieldsConfiguration(value: AccountingEntriesFieldsConfiguration | undefined) {
    this.fieldsConfig = value;
    if (value != undefined) {
      this.formGroup.get('option')?.setValue(null);
      this.configurationOptions = [];
      if (value.paymentProcessorConfiguration != null) {
          this.configurationOptions.push(configurationOptionDefinitions['PAYMENT']);
      }
      if (value.sellInvoiceProcessorConfiguration != null) {
        this.configurationOptions.push(configurationOptionDefinitions['SELL-INVOICE']);
      }
      if (value.buyInvoiceProcessorConfiguration != null) {
        this.configurationOptions.push(configurationOptionDefinitions['BUY-INVOICE']);
      }
      if (value.paymentProcessorConfiguration != null && value.sellInvoiceProcessorConfiguration != null) {
        this.configurationOptions.push(configurationOptionDefinitions['PAYMENT_SELL-INVOICE']);
      }
      if (value.paymentProcessorConfiguration != null && value.buyInvoiceProcessorConfiguration != null) {
        this.configurationOptions.push(configurationOptionDefinitions['PAYMENT_BUY-INVOICE']);
      }
      if (value.sellInvoiceProcessorConfiguration != null && value.buyInvoiceProcessorConfiguration != null) {
        this.configurationOptions.push(configurationOptionDefinitions['SELL-INVOICE_BUY-INVOICE']);
      }
      if (value.paymentProcessorConfiguration != null && value.sellInvoiceProcessorConfiguration != null && value.buyInvoiceProcessorConfiguration != null) {
        this.configurationOptions.push(configurationOptionDefinitions['PAYMENT_SELL-INVOICE_BUY-INVOICE']);
      }
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formGroup = this.fb.group({
      option: [null, Validators.required],
      paymentProcessorConfiguration: this.paymentConfigurationComponent?.formGroup,
      sellInvoiceProcessorConfiguration: this.sellInvoiceConfigurationComponent?.formGroup,
      buyInvoiceProcessorConfiguration: this.buyInvoiceConfigurationComponent?.formGroup,
      analyticProcessorConfiguration: this.analyticConfigurationComponent?.formGroup,
    });
  }

  compareSelectionObjects(object1: OptionTypesConfiguration, object2: OptionTypesConfiguration) {
    return object1 && object2 && object1.label == object2.label;
  }
}