import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Observable } from 'rxjs';
import { ThirdPartsAuthenticationList } from '~modules/thirdparts/common/entities/third-parts-authentication-list.entity';
import { ThirdPartsStripeAuthenticationService } from '../../services/third-parts-stripe-authentication.service';


@Component({
  selector: 'app-stripe-accounting-entries-api-exporter-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule
  ],
  templateUrl: './stripe-ae-api-exporter-configuration.component.html',
  styleUrls: ['./stripe-ae-api-exporter-configuration.component.scss']
})
export class StripeAccountingEntriesApiExporterConfigurationComponent {
  formGroup: FormGroup;

  connectionsOptions$?: Observable<ThirdPartsAuthenticationList[]>;

  constructor(
    readonly fb: FormBuilder,
    protected readonly authenticationService: ThirdPartsStripeAuthenticationService,

  ) {
    this.formGroup = fb.group({
      authenticationId: [null, Validators.required]
    });

    this.connectionsOptions$ = this.authenticationService.list$();
  }
}