import { NgModule, ModuleWithProviders } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { ForgottenPasswordService } from './services/forgotten-password.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    HttpClientModule
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {

      ngModule: AuthModule,
      providers: [
        AuthenticationService,
        ForgottenPasswordService,
      ],
    };
   }
}
