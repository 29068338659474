import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyOfferDetails, QuotaObject } from '../entities/company-offer-details';
import { CompanyConnectorsOptionIncluded } from '~modules/company/entities/company-connectors-option-included.entity';

@Injectable()
export class OfferService {

  constructor(
      private http: HttpClient,
  ) { }

  getCompanyOfferDetails$(companyId: number): Observable<CompanyOfferDetails> {
    const url = `/api/company/${companyId}/offer`;
    return this.http.get<CompanyOfferDetails>(url);
  }

  getCurrentUserCompanyOfferQuotasLimit$(): Observable<Record<QuotaObject,number>> {
    const url = `/api/company/current/offer/quotas/limit`;
    return this.http.get<Record<QuotaObject,number>>(url);
  }

  getCurrentUserCompanyCountForQuotaObject$(quotaObject: QuotaObject): Observable<number> {
    const url = `/api/company/current/offer/quotas/${quotaObject}/count`;
    return this.http.get<number>(url);
  }

  getCurrentUserCompanyConnectorsOptionIncluded$(): Observable<CompanyConnectorsOptionIncluded> {
    const url = `/api/company/current/offer/connectors-option/included`;
    return this.http.get<CompanyConnectorsOptionIncluded>(url);
  }
}
