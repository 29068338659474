import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MigrationProjectList } from '../entities/migration-project-list.entity';
import { MigrationProjectCreateRequest } from '../entities/migration-project-create-request.entity';
import { MigrationProjectDetails } from '../entities/details/migration-project-details.entity';
import { MigrationProjectJournalMappingValidationRuleType } from '../entities/mapping/folder/migration-project-journal-mapping-validation-rule-type.enum';
import { MigrationProjectJournalMappingTransformationRuleUpdateList } from '../entities/mapping/folder/migration-project-journal-mapping-tranformation-rule-update-list.entity';
import { MigrationProjectJournalMappingTransformationRuleCreateUpdateRequest } from '../entities/mapping/folder/migration-project-journal-mapping-tranformation-rule-update-request.entity';
import { MigrationProjectUpdateAuthenticationRequest } from '../entities/migration-project-update-authentication-request.entity';

@Injectable()
export class MigrationProjectService {

  constructor(
      private http: HttpClient,
  ) { }

  listAllProjects$(): Observable<MigrationProjectList[]> {
    const url = `/api/migration/project/list`;
    return this.http.get<MigrationProjectList[]>(url);
  }

  createProject$(project: MigrationProjectCreateRequest): Observable<void> {
    const url = `/api/migration/project`;
    return this.http.post<void>(url, project);
  }

  getDetails$(id: number): Observable<MigrationProjectDetails> {
    const url = `/api/migration/project/${id}`;
    return this.http.get<MigrationProjectDetails>(url);
  }

  updateAuthentication$(id: number, request: MigrationProjectUpdateAuthenticationRequest): Observable<void> {
    const url = `/api/migration/project/${id}/authentication`;

    return this.http.post<void>(url, request);
  }

  getJournalMappingValidationRulesById$(id: number): Observable<MigrationProjectJournalMappingValidationRuleType[]> {
    const url = `/api/migration/project/${id}/journalMappingValidationRules`;
    return this.http.get<MigrationProjectJournalMappingValidationRuleType[]>(url);
  }

  setJournalMappingValidationRulesById$(id: number, rules: MigrationProjectJournalMappingValidationRuleType[]): Observable<void> {
    const url = `/api/migration/project/${id}/journalMappingValidationRules`;

    return this.http.post<void>(url, rules);
  }

  getJournalMappingTransformationRulesById$(id: number): Observable<MigrationProjectJournalMappingTransformationRuleUpdateList[]> {
    const url = `/api/migration/project/${id}/journalMappingTransformationRules`;
    return this.http.get<MigrationProjectJournalMappingTransformationRuleUpdateList[]>(url);
  }

  updateJournalMappingTransformationRule$(id: number, request: MigrationProjectJournalMappingTransformationRuleCreateUpdateRequest): Observable<void> {
    const url = `/api/migration/project/${id}/journalMappingTransformationRule`;

    return this.http.post<void>(url, request);
  }

  deleteJournalMappingTransformationRule$(id: number, journalMappingTransformationRuleId: number): Observable<void> {
    const url = `/api/migration/project/${id}/journalMappingTransformationRule/${journalMappingTransformationRuleId}`;

    return this.http.delete<void>(url);
  }
}
