export type CreateDlItemFromBlobParams = {
    blob: Blob,
    fileName: string;
}

const defaultParams: Partial<CreateDlItemFromBlobParams> = {
    fileName: 'undefined-filename'
};

export function userDownloadItem(
    params: CreateDlItemFromBlobParams
): void {
    const {fileName, blob}: CreateDlItemFromBlobParams = {
        ...defaultParams,
        ...params,
    };
    // Create a donwloadable element
    const a: HTMLAnchorElement = document.createElement('a');
    a.download = fileName;
    a.rel = 'noopener';
    a.href = window.URL.createObjectURL(blob);
    a.target = '_blank';
    a.click();
}