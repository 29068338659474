import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { combineLatest, ReplaySubject, startWith, Subscription } from 'rxjs';
import { buyInvoiceGenerationOptions, InvoiceGenerationOptionEnum, sellInvoiceGenerationOptions } from '~modules/connectors/constants/Invoice-generation.option';
import { buyInvoiceAmountDifferenceOptions, InvoiceAmountDifferenceOptionEnum, sellInvoiceAmountDifferenceOptions } from '~modules/connectors/constants/invoice-amount-difference.option';
import { InvoiceFieldsConfiguration, defaultAccountLabelMaxLength, defaultJournalCodeMaxLength, defaultJournalLabelMaxLength, defaultMainAccountMaxLength, defaultDynamicConfigMaxLength, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { InvoiceProcessorConfiguration } from '~modules/tools/extract-accounting-entries/entities/invoice-processor-configuration.entity';
import { VatAccountConfigurationComponent } from '../vat-account-configuration/vat-account-configuration.component';
import { MatDividerModule } from '@angular/material/divider';
import { TextFieldModule } from '@angular/cdk/text-field';
import { InvoiceThirdPartyProcessorConfigurationComponent } from '../invoice-third-party-processor-configuration/invoice-third-party-processor-configuration.component';
import { defaultDescriptionDynamicFieldOption, defaultDescriptionStandardFieldOption } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/default-transformation-fields';

type InvoiceDirection = 'SELL'|'BUY';

@Component({
  selector: 'app-invoice-processor-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    VatAccountConfigurationComponent,
    InvoiceThirdPartyProcessorConfigurationComponent,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDividerModule,
    TextFieldModule
  ],
  templateUrl: './invoice-processor-configuration.component.html',
  styleUrls: ['./invoice-processor-configuration.component.scss']
})
export class InvoiceProcessorConfigurationComponent implements OnDestroy {

  formGroup!: FormGroup;

  hasDescriptionTemplateSubscription?: Subscription;
  useSuggestedProductsAccountsSubscription?: Subscription;
  generationOptionsSubscription?: Subscription;
  descriptionOptionSubscription?: Subscription;
  fieldsConfig: InvoiceFieldsConfiguration | undefined;
  invoiceConfig: InvoiceProcessorConfiguration | undefined;

  invoiceDirection$: ReplaySubject<InvoiceDirection> = new ReplaySubject(1);
  
  invoiceGenerationOptions = sellInvoiceGenerationOptions;
  invoiceAmountDifferenceOptions = sellInvoiceAmountDifferenceOptions;

  @Input()
  set invoiceDirection(value: InvoiceDirection) {
    this.invoiceDirection$.next(value);
    if (value == 'SELL') {
      this.invoiceAmountDifferenceOptions = sellInvoiceAmountDifferenceOptions;
    } else {
      this.invoiceAmountDifferenceOptions = buyInvoiceAmountDifferenceOptions;
    }
  }

  @Input()
  toolLabel!: string | undefined;

  @ViewChild('productVatAccounts')
  set productVatAccountsComponent(value: VatAccountConfigurationComponent) {
    this.formGroup.setControl('productVatAccounts', value?.formArray);
  }

  @ViewChild('collectedVatAccounts')
  set collectedVatAccountsComponent(value: VatAccountConfigurationComponent) {
    this.formGroup.setControl('collectedVatAccounts', value?.formArray);
  }

  @ViewChild('thirdPartyAccount')
  set thirdPartyAccountComponent(value: InvoiceThirdPartyProcessorConfigurationComponent) {
    this.formGroup.setControl('thirdPartyAccount', value?.formGroup);
  }

  @Input()
  set invoiceConfiguration (value: InvoiceProcessorConfiguration | undefined) {
    if (value) {
      this.invoiceConfig = value;
      // search correct option and then in case of dynamic label we can override default option value with the one from config
      this.formGroup.get('descriptionOption')?.setValue(this.findDescriptionOptionByDescriptionProperties(value.descriptionTemplate, value.hasDescriptionTemplate))
      this.formGroup.patchValue(value);
    }
  }

  @Input()
  set fieldsConfiguration (value: InvoiceFieldsConfiguration | undefined) {
    if (value != undefined) {
      this.fieldsConfig = value;

      const journalCodeMaxLength = value.journal.code.maxLength != null ? value.journal.code.maxLength : defaultJournalCodeMaxLength;
      const journalLabelMaxLength = value.journal.label.maxLength != null ? value.journal.label.maxLength : defaultJournalLabelMaxLength;
      const debitAdjustmentAccountMaxLength = value.debitAdjustment.account.maxLength != null ? value.debitAdjustment.account.maxLength : defaultMainAccountMaxLength;
      const debitAdjustmentAccountLabelMaxLength = value.debitAdjustment.label?.maxLength != null ? value.debitAdjustment.label?.maxLength : defaultAccountLabelMaxLength;
      const creditAdjustmentAccountMaxLength = value.creditAdjustment.account.maxLength != null ? value.creditAdjustment.account.maxLength : defaultMainAccountMaxLength;
      const creditAdjustmentAccountLabelMaxLength = value.creditAdjustment.label?.maxLength != null ? value.creditAdjustment.label?.maxLength : defaultAccountLabelMaxLength;
      const descriptionTemplateMaxLength = value.description.descriptionTemplate.maxLength != null ? value.description.descriptionTemplate.maxLength : defaultDynamicConfigMaxLength;

      this.formGroup.get('journalCode')?.setValidators([Validators.required, Validators.maxLength(journalCodeMaxLength)])
      this.formGroup.get('journalCode')?.setValue(value.journal.code.defaultValue);
      this.formGroup.get('journalLabel')?.setValidators([Validators.maxLength(journalLabelMaxLength)])
      this.formGroup.get('journalLabel')?.setValue(value.journal.label.defaultValue);

      // the next lines will update the invoiceGenerationOptions
      this.formGroup.get('useSuggestedProductsAccounts')?.setValue(value.generation.useSuggestedProductsAccounts.defaultValue);
      // we only updates the generation option if available on invoiceGenerationOptions
      if(this.invoiceGenerationOptions.filter(option => option.value === value.generation.option.defaultValue).length > 0) {
        this.formGroup.get('generationOption')?.setValue(value.generation.option.defaultValue);
      }

      this.formGroup.get('useSuggestedVatsAccounts')?.setValue(value.useSuggestedVatsAccounts.defaultValue);

      this.formGroup.get('amountDifferenceOption')?.setValue(value.amountDifferenceOption.defaultValue);
      this.formGroup.get('debitAdjustmentAccount')?.setValidators([Validators.required, Validators.maxLength(debitAdjustmentAccountMaxLength)]);
      this.formGroup.get('debitAdjustmentAccount')?.setValue(value.debitAdjustment.account.defaultValue);
      this.formGroup.get('debitAdjustmentAccountLabel')?.setValidators([Validators.maxLength(debitAdjustmentAccountLabelMaxLength)]);
      this.formGroup.get('debitAdjustmentAccountLabel')?.setValue(value.debitAdjustment.label?.defaultValue);
      this.formGroup.get('creditAdjustmentAccount')?.setValidators([Validators.required, Validators.maxLength(creditAdjustmentAccountMaxLength)]);
      this.formGroup.get('creditAdjustmentAccount')?.setValue(value.creditAdjustment.account.defaultValue);
      this.formGroup.get('creditAdjustmentAccountLabel')?.setValidators([Validators.maxLength(creditAdjustmentAccountLabelMaxLength)]);
      this.formGroup.get('creditAdjustmentAccountLabel')?.setValue(value.creditAdjustment.label?.defaultValue);

      let defaultOption: DescriptionOption;
      let descriptionTemplateValue: string|null = null;
      if (value.description.defaultOptionValue == null) {
        defaultOption = defaultDescriptionStandardFieldOption;
      } else {
        defaultOption = value.description.options.find((option) => option.descriptionTemplateValue === value.description.defaultOptionValue) || defaultDescriptionDynamicFieldOption;
        descriptionTemplateValue = value.description.defaultOptionValue || defaultOption.descriptionTemplateValue || value.description.descriptionTemplate.defaultValue;
      }
      this.formGroup.get('descriptionOption')?.setValue(defaultOption);
      this.formGroup.get('hasDescriptionTemplate')?.setValue(defaultOption.hasDescriptionTemplate);
      this.formGroup.get('descriptionTemplate')?.setValidators([Validators.maxLength(descriptionTemplateMaxLength)]);
      this.formGroup.get('descriptionTemplate')?.setValue(descriptionTemplateValue);
      this.formGroup.markAllAsTouched();
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formGroup = new FormGroup({
      journalCode: new FormControl('', [Validators.required, Validators.maxLength(defaultJournalCodeMaxLength)]),
      journalLabel: new FormControl('', [Validators.maxLength(defaultJournalLabelMaxLength)]),
      useSuggestedProductsAccounts: new FormControl(null, [Validators.required]),
      generationOption: new FormControl('', [Validators.required]),
      productAccount: new FormControl('', [Validators.maxLength(defaultMainAccountMaxLength)]),
      productAccountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      useSuggestedVatsAccounts: new FormControl(null, [Validators.required]),
      amountDifferenceOption: new FormControl('', [Validators.required]),
      debitAdjustmentAccount: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      debitAdjustmentAccountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      creditAdjustmentAccount: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      creditAdjustmentAccountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      descriptionOption: new FormControl(null),
      hasDescriptionTemplate: new FormControl(false, [Validators.required]),
      descriptionTemplate: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)])
    });

    this.descriptionOptionSubscription = this.formGroup.get('descriptionOption')?.valueChanges.subscribe({
      next: (value) => {
        this.formGroup.get('hasDescriptionTemplate')?.setValue(value.hasDescriptionTemplate);
        if (value.hasDescriptionTemplate === true) {
          this.formGroup.get('descriptionTemplate')?.setValue(
            value.descriptionTemplateValue ? value.descriptionTemplateValue : this.fieldsConfig?.description.descriptionTemplate.defaultValue
          );
        } else {
          this.formGroup.get('descriptionTemplate')?.setValue(null);
        }
        this.formGroup.get('descriptionTemplate')?.updateValueAndValidity();
      }
    });

    const useSuggestedProductsAccountsFormValue = this.formGroup.get('useSuggestedProductsAccounts');
    if (useSuggestedProductsAccountsFormValue != null) {
      this.useSuggestedProductsAccountsSubscription = combineLatest([
        this.invoiceDirection$,
        useSuggestedProductsAccountsFormValue.valueChanges.pipe(startWith(null))
      ]).subscribe({
        next: ([invoiceDirection, value]) => {
          this.updateInvoiceGenerationOptions(invoiceDirection, value);
        }
      });
    }

    this.generationOptionsSubscription = this.formGroup.get('generationOption')?.valueChanges.subscribe({
      next: (value) => {
        this.updateGenerationOptionFormGroup(value);
      }
    });

  }

  ngOnDestroy(): void {
    this.hasDescriptionTemplateSubscription?.unsubscribe();
    this.useSuggestedProductsAccountsSubscription?.unsubscribe();
    this.generationOptionsSubscription?.unsubscribe();
    this.descriptionOptionSubscription?.unsubscribe();
  }

  compareFunction(o1: InvoiceGenerationOptionEnum|InvoiceAmountDifferenceOptionEnum, o2: InvoiceGenerationOptionEnum|InvoiceAmountDifferenceOptionEnum) {
    return (o1 == null && o2 == null) || (o1 != null && o2 != null && o1 === o2);
  }

  avoidLineBreak(event: KeyboardEvent) {
    if(event.key == 'Enter' || (event.shiftKey && event.key == 'Enter')) {
      event.preventDefault();
    }
  }

  updateInvoiceGenerationOptions(invoiceDirection: InvoiceDirection, useSuggestedProductsAccounts?: boolean|null) {
    const avalaibleOptions: InvoiceGenerationOptionEnum[] = [];
    if (useSuggestedProductsAccounts != null && useSuggestedProductsAccounts === true) {
      if (this.fieldsConfig != null) {
        if (this.fieldsConfig.generation.oneLinePerInvoiceLine != null) {
          avalaibleOptions.push(InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE);
          avalaibleOptions.push(InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT);
        }
      } else {
        avalaibleOptions.push(InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE);
        avalaibleOptions.push(InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT);
      }
    } else {
      if (this.fieldsConfig != null) {
        if (this.fieldsConfig.generation.oneGlobalLine != null) {
          avalaibleOptions.push(InvoiceGenerationOptionEnum.ONE_GLOBAL_LINE);
        }
        if (this.fieldsConfig.generation.oneLinePerInvoiceLine != null) {
          avalaibleOptions.push(InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE);
        }
        if (this.fieldsConfig.generation.oneLinePerVatRate != null) {
          avalaibleOptions.push(InvoiceGenerationOptionEnum.ONE_LINE_PER_VAT_RATE);
        }
      } else {
        avalaibleOptions.push(InvoiceGenerationOptionEnum.ONE_GLOBAL_LINE, InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE, InvoiceGenerationOptionEnum.ONE_LINE_PER_VAT_RATE);
      }
    }

    let invoiceBaseGenerationOptions;
    if (invoiceDirection == 'BUY') {
      invoiceBaseGenerationOptions = buyInvoiceGenerationOptions;
    } else {
      invoiceBaseGenerationOptions = sellInvoiceGenerationOptions;
    }

    this.invoiceGenerationOptions = invoiceBaseGenerationOptions.filter(option => avalaibleOptions.includes(option.value));
    if(this.fieldsConfig?.generation.option.defaultValue != null && avalaibleOptions.includes(this.fieldsConfig?.generation.option.defaultValue)) {
      this.formGroup.get('generationOption')?.setValue(this.fieldsConfig?.generation.option.defaultValue);
    } else {
      this.formGroup.get('generationOption')?.setValue(null);
    }
  }

  updateGenerationOptionFormGroup(value: InvoiceGenerationOptionEnum) {
    if (value === InvoiceGenerationOptionEnum.ONE_LINE_PER_VAT_RATE && (this.fieldsConfig == null || this.fieldsConfig?.generation.oneLinePerVatRate != null)) {
      this.formGroup.get('productAccount')?.setValidators([]);
      this.formGroup.get('productAccount')?.setValue(null);
      this.formGroup.get('productAccountLabel')?.setValidators([]);
      this.formGroup.get('productAccountLabel')?.setValue(null);
    } else if (value === InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE && (this.fieldsConfig == null || this.fieldsConfig?.generation.oneLinePerInvoiceLine != null)) {
      this.formGroup.get('productAccount')?.setValidators([]);
      this.formGroup.get('productAccount')?.setValue(null);
      this.formGroup.get('productAccountLabel')?.setValidators([]);
      this.formGroup.get('productAccountLabel')?.setValue(null);
    } else if (value === InvoiceGenerationOptionEnum.ONE_GLOBAL_LINE && (this.fieldsConfig == null ||  this.fieldsConfig?.generation.oneGlobalLine != null)) {
      const productAccountMaxLength = this.fieldsConfig?.generation.oneGlobalLine?.product.account.maxLength != null ? this.fieldsConfig?.generation.oneGlobalLine?.product.account.maxLength : defaultMainAccountMaxLength;
      const productAccountLabelMaxLength = this.fieldsConfig?.generation.oneGlobalLine?.product.label?.maxLength != null ? this.fieldsConfig?.generation.oneGlobalLine?.product.label?.maxLength : defaultAccountLabelMaxLength;

      this.formGroup.get('productAccount')?.setValidators([Validators.required, Validators.maxLength(productAccountMaxLength)]);
      this.formGroup.get('productAccount')?.setValue(this.fieldsConfig?.generation.oneGlobalLine?.product.account.defaultValue);
      this.formGroup.get('productAccountLabel')?.setValidators([Validators.maxLength(productAccountLabelMaxLength)]);
      this.formGroup.get('productAccountLabel')?.setValue(this.fieldsConfig?.generation.oneGlobalLine?.product.label?.defaultValue);
    }
  }

  compareSelectionObjects(object1: DescriptionOption, object2: DescriptionOption) {
    return object1 && object2 && object1.label == object2.label;
  }

  findDescriptionOptionByDescriptionProperties(descriptionTemplate: string, hasDescriptionTemplate: boolean): DescriptionOption | undefined {
    if (hasDescriptionTemplate === false) {
      return defaultDescriptionStandardFieldOption;
    }
    const result = this.fieldsConfig?.description.options.find((option => option.descriptionTemplateValue === descriptionTemplate));
    if (result) {
      return result;
    } else {
      return defaultDescriptionDynamicFieldOption;
    }
  }

}