
<form [formGroup]="formGroup">
    <label for="folderId"><h4><mat-icon>description</mat-icon>&nbsp;Agiris / Isacompta / Isagri</h4></label>
    <mat-form-field>
      <mat-label>Identifiant dossier</mat-label>
      <input matInput formControlName="folderId" placeholder="000001">
    </mat-form-field>
    <label for="generatePartialLettering"><h4><mat-icon>account_tree</mat-icon>&nbsp;Lettrage</h4></label>
    <mat-slide-toggle formControlName="generatePartialLettering">
      <div class="text-and-icon-container">
        <span>Générer les lettrages partiels disponibles</span>
      </div>
    </mat-slide-toggle>
</form>