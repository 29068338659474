import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExtractAccountingEntriesApiRequest } from '../entities/extract-accounting-entries-api-request.entity';
import { ExtractAccountingEntriesFileRequest } from '../entities/extract-accounting-entries-file-request.entity';

@Injectable()
export class ExtractAccountingEntriesService {

  constructor(
      private http: HttpClient,
  ) { }

  extractAccountingEntriesFromApi$(request: ExtractAccountingEntriesApiRequest): Observable<Blob> {
    const url = `/api/tools/extract-accounting-entries/api`;
    return this.http.post(url, request, {responseType: 'blob'});
  }

  extractAccountingEntriesFromFile$(request: ExtractAccountingEntriesFileRequest): Observable<Blob> {
    const url = `/api/tools/extract-accounting-entries/file`;
    return this.http.post(url, request, {responseType: 'blob'});
  }
}
