<ng-container [formGroup]="formGroup">
  <ng-container formArrayName="formArray">
    <div class="header-row">
      <div class="header type">Type de compte</div>
      <div class="header account">Compte</div>
      <div class="header axis">Axe</div>
      <div class="header tag">Tag</div>
      <div class="header value">Valeur</div>
    </div>
    <ng-container *ngFor="let accountRule of formArray.controls; index as i" [formGroupName]="i">
      <div class="row flex-center">
        <mat-form-field class="type">
          <mat-select formControlName="type" (valueChange)="onTypeChange($event, accountRule)" [compareWith]="compareFunction">
            <mat-option *ngFor="let option of analyticRuleTypes"
              [value]="option.value"
              matTooltipPosition="right"
              [matTooltip]="option.tooltip"
            >{{option.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="account">
          <input matInput
                  formControlName="account"
                  autocomplete="off">
        </mat-form-field>
        <mat-form-field class="axis">
          <input matInput
                  formControlName="axis"
                  autocomplete="off">
        </mat-form-field>
        <mat-form-field class="tag">
          <input matInput
                  formControlName="tag"
                  autocomplete="off">
        </mat-form-field>
        <mat-form-field class="value">
          <input matInput
                  formControlName="value"
                  type="number"
                  autocomplete="off">
          <span matSuffix>%</span>
        </mat-form-field>
        <button mat-button (click)="deleteAccountRule(i)" [disabled]="fieldsConfig?.canDelete === false">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<button mat-stroked-button (click)="addAccountRule()" [disabled]="fieldsConfig?.canAdd === false"><mat-icon>add</mat-icon>&nbsp;{{ addLabel || 'Ajouter une règle analytique' }}</button>