import { RunFieldsConfiguration } from "../../field-type";


export const uberEatsFileRunFieldsConfiguration: RunFieldsConfiguration = {

    runCursor: {
        visible: false
    },
    file: {
        visible: true,
        mimeType: "text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }

}