import { InvoiceGenerationOptionEnum } from '~modules/connectors/constants/Invoice-generation.option';
import { InvoiceAmountDifferenceOptionEnum } from '~modules/connectors/constants/invoice-amount-difference.option';
import { AccountingEntriesFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { getBuyInvoiceDescriptionFieldOptions, getSellInvoiceDescriptionFieldOptions } from './default-transformation-fields';

const axonautSellInvoiceDescriptionOptions: DescriptionOption[] = getSellInvoiceDescriptionFieldOptions({
    withThirdPart: true
});

const axonautBuyInvoiceDescriptionOptions: DescriptionOption[] = getBuyInvoiceDescriptionFieldOptions({
    withThirdPart: true
});

export const axonautTransformationFieldsConfiguration: AccountingEntriesFieldsConfiguration = {
    toolLabel: 'Axonaut',

    sellInvoiceProcessorConfiguration: {
        journal: {
            code: {
                defaultValue: 'VT'
            },
            label: {
                defaultValue: 'Ventes'
            }
        },
        thirdPartyAccount: {
            useSuggestedAccounts: {
                defaultValue: true
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'Clients'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'CLIENTS'
            },
            auxiliaryLabelDynamic: {
                defaultValue: '{{ buyer.mainName }}'
            },
            auxiliaryAccountDynamic: {
                defaultValue: '{{ buyer.mainName | normalize | upper | left(8) }}'
            }
        },
        generation: {
            useSuggestedProductsAccounts: {
                defaultValue: true
            },
            option: {
                defaultValue: InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT
            },
            oneLinePerVatRate: {
                productVats: {
                    columns: {
                        countryCode: {
                            visible: false,
                            canEdit: false
                        },
                        regime: {
                            visible: false,
                            canEdit: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '701099',
                        accountLabel: 'Produits à reclasser'
                    },
                    accounts: [
                        {
                            countryCode: null,
                            regime: null,
                            rate: 0,
                            account: '701000',
                            accountLabel: 'Ventes TVA 0%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 5.5,
                            account: '701055',
                            accountLabel: 'Ventes TVA 5,5%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 10,
                            account: '701010',
                            accountLabel: 'Ventes TVA 10%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 20,
                            account: '701020',
                            accountLabel: 'Ventes TVA 20%'
                        }
                    ]
                }
            },
            oneLinePerInvoiceLine: {
                productVats: {
                    columns: {
                        countryCode: {
                            visible: false,
                            canEdit: false
                        },
                        regime: {
                            visible: false,
                            canEdit: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '701099',
                        accountLabel: 'Produits à reclasser'
                    },
                    accounts: [
                        {
                            countryCode: null,
                            regime: null,
                            rate: 0,
                            account: '701000',
                            accountLabel: 'Ventes TVA 0%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 5.5,
                            account: '701055',
                            accountLabel: 'Ventes TVA 5,5%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 10,
                            account: '701010',
                            accountLabel: 'Ventes TVA 10%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 20,
                            account: '701020',
                            accountLabel: 'Ventes TVA 20%'
                        }
                    ]
                }
            },
            oneGlobalLine: {
                product: {
                    account: {
                        defaultValue: '701000'
                    },
                    label: {
                        defaultValue: 'Produit'
                    }
                }
            }
        },
        useSuggestedVatsAccounts: {
            defaultValue: true
        },
        collectedVats: {
            columns: {
                countryCode: {
                    visible: false,
                    canEdit: false
                },
                regime: {
                    visible: false,
                    canEdit: false
                }
            },
            defaultAccount: {
                countryCode: null,
                regime: null,
                rate: null,
                account: '44571099',
                accountLabel: 'TVA à reclasser'
            },
            accounts: [
                {
                    countryCode: null,
                    regime: null,
                    rate: 5.5,
                    account: '44571055',
                    accountLabel: 'TVA collectée 5,5%'
                },
                {
                    countryCode: null,
                    regime: null,
                    rate: 10,
                    account: '44571010',
                    accountLabel: 'TVA collectée 10%'
                },
                {
                    countryCode: null,
                    regime: null,
                    rate: 20,
                    account: '44571020',
                    accountLabel: 'TVA collectée 20%'
                }
            ]
        },
        amountDifferenceOption: {
            defaultValue: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
        },
        debitAdjustment: {
            account: {
                defaultValue: "658"
            },
            label: {
                defaultValue: "Charges diverses de gestion courante"
            }
        },
        creditAdjustment: {
            account: {
                defaultValue: "758"
            },
            label: {
                defaultValue: "Produits divers de gestion courante"
            }
        },
        description: {
            options: axonautSellInvoiceDescriptionOptions,
            descriptionTemplate: {
                defaultValue: '{{ invoice.type }} • {{ invoice.invoiceFunctionalId | default(invoice.invoiceId) }} • {{ invoice.creationDate | date("yyyy-MM-dd") }}'
            }
        }
    },

    buyInvoiceProcessorConfiguration: {
        journal: {
            code: {
                defaultValue: 'ACH'
            },
            label: {
                defaultValue: 'Achats'
            }
        },
        thirdPartyAccount: {
            useSuggestedAccounts: {
                defaultValue: true
            },
            account: {
                defaultValue: '401'
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'Fournisseurs'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'FOURNISSEURS'
            },
            auxiliaryLabelDynamic: {
                defaultValue: '{{ seller.mainName }}'
            },
            auxiliaryAccountDynamic: {
                defaultValue: '{{ seller.mainName | normalize | upper | left(8) }}'
            }
        },
        generation: {
            useSuggestedProductsAccounts: {
                defaultValue: true
            },
            option: {
                defaultValue: InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT
            },
            oneLinePerInvoiceLine: {
                productVats: {
                    canAdd: false,
                    columns: {
                        countryCode: {
                            visible: false,
                            canEdit: false
                        },
                        regime: {
                            visible: false,
                            canEdit: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '471',
                        accountLabel: 'Charges à reclasser'
                    }
                }
            },
            oneGlobalLine: {
                product: {
                    account: {
                        defaultValue: '471'
                    },
                    label: {
                        defaultValue: 'Charges à reclasser'
                    }
                }
            }
        },
        useSuggestedVatsAccounts: {
            defaultValue: true
        },
        collectedVats: {
            columns: {
                countryCode: {
                    visible: false,
                    canEdit: false
                },
                regime: {
                    visible: false,
                    canEdit: false
                }
            },
            defaultAccount: {
                countryCode: null,
                regime: null,
                rate: null,
                account: '44566099',
                accountLabel: 'TVA à reclasser'
            },
            accounts: [
                {
                    countryCode: null,
                    regime: null,
                    rate: 5.5,
                    account: '44566055',
                    accountLabel: 'TVA déductible 5,5%'
                },
                {
                    countryCode: null,
                    regime: null,
                    rate: 10,
                    account: '44566010',
                    accountLabel: 'TVA déductible 10%'
                },
                {
                    countryCode: null,
                    regime: null,
                    rate: 20,
                    account: '44566020',
                    accountLabel: 'TVA déductible 20%'
                }
            ]
        },
        amountDifferenceOption: {
            defaultValue: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
        },
        debitAdjustment: {
            account: {
                defaultValue: "658"
            },
            label: {
                defaultValue: "Charges diverses de gestion courante"
            }
        },
        creditAdjustment: {
            account: {
                defaultValue: "758"
            },
            label: {
                defaultValue: "Produits divers de gestion courante"
            }
        },
        description: {
            options: axonautBuyInvoiceDescriptionOptions,
            descriptionTemplate: {
                defaultValue: '{{ invoice.type }} • {{ invoice.invoiceFunctionalId | default(invoice.invoiceId) }} • {{ invoice.creationDate | date("yyyy-MM-dd") }}'
            }
        }
    },

    analyticProcessorConfiguration: {
        isEnabled: {
            defaultValue: false
        },
        accountRules: {}
    }
}