import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { FecVerifierResult } from '../entities/fecverifier-result.entity';
import { UploadFilesService } from '~modules/upload-files/upload-files.service';
import { RemoteFileEntity } from '~modules/upload-files/entities/remote-file.entity';

@Injectable()
export class FecVerifierService {

  constructor(
      private readonly http: HttpClient,
      private readonly uploadFileService: UploadFilesService,
  ) { }

  async uploadFec(
     file: File,
  ) {
    return this.uploadFileService.uploadFile(file);
  }

  verifyFec$(
    remoteFile : RemoteFileEntity, 
    rules: string[]
  ): Observable<FecVerifierResult> {

    const url = `/api/tools/fec-verifier`;

    return this.http
      .post<FecVerifierResult>(
        url, 
        {
          rules,
          remoteFileId: remoteFile.id,
          fileName: remoteFile.fileName
        },
      );
    
  }
}
