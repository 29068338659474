import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ForgottenPasswordTokenCreateRequest } from '../entities/forgotten-password-token-create-request.entity';
import { ForgottenPasswordCheckTokenAndGetUserRequest } from '../entities/forgotten-password-check-token-and-get-user-request.entity';
import { ForgottenPasswordApplyRequest } from '../entities/forgotten-password-apply-request.entity';
import { LoginSuccessResponse } from '../responses/login-success.response';
import { ForgottenPasswordCheckTokenAndGetUserResponse } from '../entities/forgotten-password-check-token-and-get-user-response.entity';


@Injectable()
export class ForgottenPasswordService {
    constructor(
        private readonly http: HttpClient,
    ) {}

    requestResetPasswordEmail(email: string) {
      const url = `/api/forgotten-password/create-token`;
      const callbackUrl = `${window.location.origin}/auth/forgotten-password/apply?userId=$userId&token=$urlToken`;
      const data: ForgottenPasswordTokenCreateRequest = {
        email,
          callbackUrl
      };
      return this.http.post<void>(url, data);
  }

  checkTokenAndGetUser(userId: number, token: string): Observable<ForgottenPasswordCheckTokenAndGetUserResponse> {
      const url = `/api/forgotten-password/check-token-and-get-user`;
      const requestData: ForgottenPasswordCheckTokenAndGetUserRequest = {
          userId,
          token
      };
      return this.http.post<ForgottenPasswordCheckTokenAndGetUserResponse>(url, requestData);
  }

  applyPassword(token: string, userId: number, password: string): Observable<LoginSuccessResponse> {
      const url = `/api/forgotten-password/apply`;
      const requestData: ForgottenPasswordApplyRequest = {
          userId,
          token,
          newPassword: password
      };

      return this.http.post<LoginSuccessResponse>(url, requestData);
  }
}
